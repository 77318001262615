import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white py-5">
      <div className="container">
        <div className="row">
          {/* Column 1: Logo and Social Icons */}
          <div className="col-md-3 footerCols">
            <img
              src="https://giftingy.com/images/Logo.png"
              alt="Company Logo"
              className="footer-logo mb-3"
            />
            <p className="text-white">Connecting people with innovative solutions for a better future.</p>
            <div className="social-icons">
              <a href="#" className="text-white me-3">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="#" className="text-white me-3">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="text-white me-3">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#" className="text-white">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>

          {/* Column 2: Services */}
          <div className="col-md-3 footerCols">
            <h5>Services</h5>
            <ul className="list-unstyled">
              <li> <Link to="/custom-flyers-printing-services">Custom Flyers Printing Services</Link> </li>
              <li> <Link to="/certificate-printing-services">Certificate Printing Services</Link> </li>
              <li> <Link to="/business-card-printing">Business Card Printing</Link> </li>
              <li> <Link to="/school-copy-printing">School Copy Printing</Link> </li>
              <li> <Link to="/presentation-printing">Presentation Printing</Link> </li>
              <li> <Link to="/custom-pocket-folder">Custom Pocket Folder</Link> </li>
              <li> <Link to="/postcard-printing-and-designing">Postcard Printing & Designing</Link> </li>
            </ul>
          </div>

          {/* Column 3: More Services */}
          <div className="col-md-3 footerCols">
            <br></br>
            <ul className="list-unstyled">
              <li> <Link to="/custom-envelops">Custom Envelops</Link> </li>
              <li> <Link to="/custom-notepads">Custom Notepads</Link> </li>
              <li> <Link to="/stamps-and-embossers">Stamps and Embossers</Link> </li>
              <li> <Link to="/document-printing-and-binding">Document Printing & Binding</Link> </li>
              <li> <Link to="/rack-card-design">Rack Card Design</Link> </li>
              <li> <Link to="/door-hanger-printing">Door Hanger Printing</Link> </li>
              <li> <Link to="/custom-note-cards">Custom Note Cards</Link> </li>
            </ul>
          </div>

          {/* Column 4: Contact Information */}
          <div className="col-md-3 footerCols">
            <h5>Contact Info</h5>
            <ul className="list-unstyled">
              <li>
                <a href="tel:+11234567890"><i className="fas fa-phone me-2"></i>+1 (123) 456-7890</a>
              </li>
              <li>
                <a href="mailto:info@example.com"><i className="fas fa-envelope me-2"></i>info@example.com</a>
              </li>
              <li>
                <a href="https://maps.google.com/?q=123+Main+Street,City,Country" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-map-marker-alt me-2"></i>123 Main Street, City, Country
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
