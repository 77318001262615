import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const CustomNotepads = () => {

    const servicesFaqData = [
        { question: "What customisation options are available for notepads?", answer: "At Printingy, we offer extensive customisation options for custom notepad printing. You can choose the size, paper type, binding style, and cover finish to suit your needs. Our personalised notepads with name service allow you to add names, logos, or brand slogans for a unique touch. These options ensure that your notepads align perfectly with your branding and purpose." },
        { question: "Can Printingy handle bulk orders for customised notepads?", answer: "Yes, Printingy specialises in customised notepads in bulk to meet large-scale business or event requirements. Our bulk printing services maintain consistent quality across all pieces, ensuring every notepad effectively reflects your brand. Bulk orders benefit from competitive pricing and efficient production timelines, making them ideal for conferences, training programmes, or promotional giveaways." },
        { question: "What materials are used for notepad printing?", answer: "We use high-quality materials for custom notepad printing, including durable paper and sturdy binding. Depending on your preferences, you can choose between matte, glossy, or textured finishes for the covers. For eco-conscious businesses, we offer recyclable materials and non-toxic inks. These options ensure your notepads are functional and aligned with your sustainability goals." },
        { question: "Does Printingy provide design assistance for notepads?", answer: "Yes, our personalised notepads with name services include design assistance. Whether you have a specific design in mind or need help creating one, our team collaborates with you to deliver a professional and visually appealing layout. We ensure every detail aligns with your branding requirements, from cover designs to internal page formatting." },
        { question: "What is the typical turnaround time for notepad printing?", answer: "Turnaround times for customised notepads in bulk depending on the order size and customisation details. Standard orders are usually completed within a few business days, while large or highly customised projects may take slightly longer. We prioritise meeting deadlines and provide clear timelines to ensure your needs are met on time." },
        { question: "Are eco-friendly options available for notepad printing?", answer: "Yes, Printingy offers eco-friendly options for custom notepad printing. These include recyclable paper, non-toxic inks, and sustainable binding materials. Our eco-conscious printing solutions allow you to maintain premium quality while reducing your environmental footprint. This makes them a great choice for businesses prioritising sustainability." },
        { question: "What industries does Printingy cater to for notepad printing?", answer: "Printingy serves various industries, including corporate offices, educational institutions, healthcare providers, and event organisers. Our customised notepads in bulk are ideal for team use, conferences, and promotional campaigns. We tailor our solutions to meet the specific needs of each sector, ensuring professional results every time." },
        { question: "Can I order notepads with different designs in one order?", answer: "Yes, we accommodate requests for multiple designs in a single order. For example, we can manage this efficiently if you require personalised notepads with names for different team members or distinct designs for various departments. This flexibility ensures your order meets all your specific requirements." },
        { question: "How do I place an order for notepad printing?", answer: "Placing an order is simple. Visit our website and select your options for customised notepads in bulk or personalised designs. Upload your design files or share your requirements, and our team will guide you through the process. We ensure a seamless experience from order placement to delivery." },
        { question: "What is the minimum order quantity for notepad printing?", answer: "We do not have strict minimum order requirements for custom notepad printing. Whether you need a small batch for individual use or large quantities for corporate events, we accommodate all order sizes. This flexibility allows businesses of any scale to benefit from our services." }
    ];
    
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Custom Notepads Printing",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
    <MetaManager 
  title="Custom Personalised Notepads Printing in Bulk "
  description="Get professional custom notepad printing. Personalised notepads with name or logo and customised in bulk for businesses and events."
  keywords="Custom notepad printing solutions,Personalised notepads with name and logo,Bulk printing of customised notepads,Professional notepad printing services"
/>


      <header className="services-header text-center py-5  serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Custom Notepads Printing Services </h1>
              <h2 className="text-muted">
              Personalised and customised notepads for businesses and events
              </h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>
          Notepads are essential for professional settings, offering a practical and branded solution for note-taking, meetings, and giveaways. Printingy specialises in custom notepad printing tailored to your business needs. Our services include personalised notepads with names, logos, or unique designs that enhance your brand visibility.
          </p>
          <p>
          We also provide customised notepads in bulk, ideal for conferences, corporate gifts, and promotional campaigns. With options for various sizes, paper types, and finishes, our notepads are crafted to meet your exact specifications. Whether you need a small batch for specific purposes or bulk orders for company-wide use, we ensure quality and consistency in every piece.</p>
          <p>Using advanced printing technology and premium materials, Printingy delivers durable, high-quality notepads that effectively reflect your professionalism and branding. Let us create notepads that are functional and impactful in promoting your business.

          </p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3  rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">Personalised Notepad Printing Solutions</h2>
                <h3 className="large-hed">High-quality, reliable, and affordable notepad printing services </h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Professional custom notepad printing with vibrant designs.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Options for personalised notepads with name or logo integration.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Affordable customised notepads in bulk for large-scale requirements.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Eco-friendly materials for sustainable printing practices.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Durable paper quality and premium finishes for long-lasting use.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Flexible sizes and layouts to meet specific business needs.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Fast turnarounds and efficient order management.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by businesses, educational institutions, and event organisers.
                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Custom notepad printing solutions"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed">High-Quality Notepads Delivered On Time</h2>
            <h3 className="small-hed">Focus on your work while we handle your notepad printing needs</h3>
            <p>
            Printingy takes pride in offering premium custom notepad printing services that combine creativity and functionality. Whether you need personalised notepads with names for team use or customised notepads in bulk for large events, we ensure flawless results.
            </p>
            <p>
            Our commitment to quality and timely delivery means you can rely on us for your printing needs without stress. With Printingy, your notepads will serve their purpose and leave a lasting impression.
            </p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default CustomNotepads;
