import React from "react";
import './AboutUs.css';
export default function AboutUs() {
  return (
    <div  className="container about-outer">
     
      <div className="row about-section">
         <div className="col-md-6">
         <h3 className=" about-sec-heading">About us <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100">
  
  <defs>
    <linearGradient id="arrowGradient" gradientTransform="rotate(45)">
      <stop offset="0%" stop-color="#63cec98a" />
      <stop offset="100%" stop-color="#eeb0f699" />
    </linearGradient>
  </defs>

 
  <g fill="none" stroke="url(#arrowGradient)" stroke-width="8" stroke-linecap="round" stroke-linejoin="round">
    
    <path d="M80,50 L60,30 L60,70 Z" />
   
    <path d="M60,50 L40,30 L40,70 Z" />
  </g>
</svg>


</h3>
            <p>MultiTV enhances content creation and management with M-FAST, streamlining video production for businesses. Our powerful tools facilitate collaboration and efficient workflows, enabling teams to deliver compelling narratives and drive impactful decision-making.</p>
         </div>
         <div className="col-md-6">
           <img className="about-img" src="/aboutusimg.png" />
         </div>
      </div>
    </div>
  );
}
