import React from "react";
import { createRoot } from "react-dom/client"; 
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async"; // Async Helmet provider
import App from "./App";
import "./index.css";

// Initialize React root
const root = createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);
