import React from "react";
import './AboutUs.css'


export default function AboutUs() {
    return (
        <div className="aboutUs">
          
           
             <div className="container">
                <div className="row">
                   <div className="col-md-6">
                   <div>
               <h2>miniweuiwebr</h2>
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget odio. Praesent laoreet velit a ligula varius, vel dapibus augue auctor. Mauris non turpis at lacus dapibus fermentum ut a nulla. Suspendisse potenti. Ut euismod, ex in feugiat hendrerit, justo augue venenatis justo, non sagittis sapien orci ac ligula.
                </p>
               </div>
                   </div>
                   <div className="col-md-6">
                   <div className="bgAbout">
                    <div className="abtImg">
                    <img  src="/imgonabotu.jpg" />
                    </div>
                  
                   </div>
                   </div>
                </div>

             </div>
           
        </div>
    );
}
