import React, { useState } from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";

const OtherServicesCarousel = () => {
  const services = [
    { image: "/assets/img/cus-fly-1.jpg", title: "Business Card Printing" },
    { image: "/assets/img/otheserv1.jpg", title: "Poster Printing" },
    { image: "/assets/img/cus-fly-1.jpg", title: "Brochure Printing" },
    { image: "/assets/img/otheserv1.jpg", title: "Banner Printing" },
    { image: "/assets/img/cus-fly-1.jpg", title: "Booklet Printing" },
    { image: "/assets/img/otheserv1.jpg", title: "Flyer Printing" },
    { image: "/assets/img/otheserv1.jpg", title: "Additional Service 1" },
    { image: "/assets/img/cus-fly-1.jpg", title: "Additional Service 2" },
    { image: "/assets/img/cus-fly-1.jpg", title: "Custom Packaging" },
    { image: "/assets/img/otheserv1.jpg", title: "Envelope Printing" },
    { image: "/assets/img/cus-fly-1.jpg", title: "Label Printing" },
    { image: "/assets/img/otheserv1.jpg", title: "Sticker Printing" },
  ];

  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <section className="other-services py-5 bg-light">
      <Container>
        <h2 className="text-center mb-4">Our Other Services</h2>
        <Row className="justify-content-center">
          {(showAll ? services : services.slice(0, 8)).map((service, idx) => {
            // Determine background color based on index
            const backgroundColor =
              idx % 2 === 0
                ? "linear-gradient(45deg, #e2f1ff, #caffce82)"
                : "linear-gradient(45deg, #fae2ff, #e3ffca82)";

            return (
              <Col lg={3} md={4} sm={6} key={idx} className="mb-4">
                <Card className="shadow-sm" style={{ background: backgroundColor }}>
                  <Card.Img
                    variant="top"
                    src={service.image}
                    alt={service.title}
                    className="img-fluid"
                  />
                  <Card.Body>
                    <Card.Title className="text-center">
                      {service.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
        <div className="text-center mt-3">
          <Button variant="primary" onClick={toggleShowAll}>
            {showAll ? "Show Less" : "Show More"}
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default OtherServicesCarousel;
