import React from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // For navigation

import TopHeader from './TopHeader';
import './Header.css'
const Header = () => {
  return (
   <div>
     <TopHeader />
  
    <Navbar bg="light" expand="lg" className="shadow-sm">
      <Container fluid>
        {/* Logo */}
        <Navbar.Brand as={Link} to="/" className="me-auto">
          <img
            src="https://giftingy.com/images/Logo.png" // Replace with your logo
            alt="Logo"
            className="d-inline-block align-top"
            style={{ height: '75px' }} // Increased height
          />
        </Navbar.Brand>

        {/* Toggle button for mobile */}
        <Navbar.Toggle aria-controls="navbar-nav" />

        <Navbar.Collapse id="navbar-nav">
          <Nav className="mx-auto"> {/* Center the menu items */}
            {/* Menu Items */}
            <Nav.Link as={Link} to="/" className="menu-item">Home</Nav.Link>
            <NavDropdown title="Business Essentials" id="navbar-dropdown" className="mega-menu">
              <NavDropdown.Item>
             
                  <Row>
                    <Col md={2}>
                      <h5>Document Printing</h5>
                      <ul className="megamenuUl">
                        <li><Link className="navbarList" to="/custom-flyers-printing-services">Printing of Certificates</Link></li>
                        <li><Link className="navbarList" to="/certificate-printing-services">Printing of Copies</Link></li>
                        <li><Link className="navbarList" to="/business-card-printing">Printing of Documents</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Flyers</Link></li>
                        <li><Link className="navbarList" to="/presentation-printing">Printing of Letterhead</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Manuals</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Postcards</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Presentations</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Resumes</Link></li>
                      </ul>
                    </Col>
                    <Col md={4}>
                    
                      <div className="megamenuUl">
                      <h5>Printing Business Essentials</h5>
                        <div className="row">
                            <div className="col-md-6">
                         <ul>
                         <li><Link className="navbarList" to="/custom-envelops">Printing of Brochures</Link></li>
                        <li><Link className="navbarList" to="/custom-notepads">Printing of Business Cards</Link></li>
                        <li><Link className="navbarList" to="/stamps-and-embossers">Printing of Envelopes</Link></li>
                        <li><Link className="navbarList" to="/document-printing-and-binding">Printing of Notepads</Link></li>
                        <li><Link className="navbarList" to="/rack-card-design">Printing of Stamps <br></br> & Embosssers</Link></li>
                        <li><Link className="navbarList" to="/rack-card-design">Printing of Door Hangers</Link></li>
                        <li><Link className="navbarList" to="/rack-card-design">Printing of Note Cards</Link></li>
                         </ul>
                            </div>
                            <div className="col-md-6">
                                    
                       <ul>
                       <li><Link className="navbarList" to="/rack-card-design">Printing of Pocket Folders</Link></li>
                        <li><Link className="navbarList" to="/rack-card-design">Printing of Rack Cards</Link></li>
                        <li><Link className="navbarList" to="/rack-card-design">Printing of Sales Sheets</Link></li>
                        <li><Link className="navbarList" to="/rack-card-design">Printing of Stickers & Labels</Link></li>
                        <li><Link className="navbarList" to="/rack-card-design">Printing of Table Tents</Link></li>
                        <li><Link className="navbarList" to="/rack-card-design">Printing of Bags</Link></li>
                        <li><Link className="navbarList" to="/rack-card-design">Printing of Hangtags</Link></li>
                       </ul>
                            </div>
                        </div>
                       
                      </div>
                    </Col>
                    <Col md={3}>
                      <h5>Speciality Prints</h5>
                      <ul className="megamenuUl">
                        <li><Link className="navbarList" to="/door-hanger-printing">Architecture, Construction, <br></br> & Engineering Prints</Link></li>
                        <li><Link className="navbarList" to="/custom-note-cards">Printing of Blueprints</Link></li>
                        <li><Link className="navbarList" to="/custom-pocket-folder">Printing of Floor Graphics</Link></li>
                    <li><Link className="navbarList" to="/postcard-printing-and-designing">Wall Graphics</Link></li>
                    <li><Link className="navbarList" to="/postcard-printing-and-designing">Window Graphics</Link></li>
                      </ul>
                    </Col>
                    <Col md={3}>
                      <img
                        src="/menutop.jpg"
                        alt="Mega Menu "
                        className="img-fluid"
                      />
                    </Col>
                  </Row>
            
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Marketing & Promotions" id="navbar-dropdown" className="mega-menu">
              <NavDropdown.Item>
             
                  <Row>
                    <Col md={6}>
                      <h5>Printing of Marketing Materials</h5>
                      <div className="megamenuUl">
                        <div className="row">
                            <div className="col-md-6">
                              <ul>
                              <li><Link className="navbarList" to="/custom-flyers-printing-services">Banners, Signs, & Posters Prints</Link></li>
                        <li><Link className="navbarList" to="/certificate-printing-services">Printing of Brochures</Link></li>
                        <li><Link className="navbarList" to="/business-card-printing">Printing of Business Cards</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Car Magnets</Link></li>
                        <li><Link className="navbarList" to="/presentation-printing">Printing of Boxes & Padded</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Mailers</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Envelopes</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Notepads</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Post-it Notes</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Wall Graphics</Link></li>

<li><Link className="navbarList" to="/school-copy-printing">Printing of Window Graphics</Link></li>
                              </ul>
                            </div>
                            <div className="col-md-6">
                              <ul>
                              <li><Link className="navbarList" to="/custom-flyers-printing-services">Printing of Door Hangers</Link></li>
                        <li><Link className="navbarList" to="/certificate-printing-services">Printing of Fabric Backdrops</Link></li>
                        <li><Link className="navbarList" to="/business-card-printing">Printing of Business Cards</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Car Magnets</Link></li>
                        <li><Link className="navbarList" to="/presentation-printing">Printing of Boxes & Padded</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Mailers</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Envelopes</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Notepads</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">Post-it Notes</Link></li>

                        <li><Link className="navbarList" to="/school-copy-printing">Printing of Table Tents</Link></li>
                       
                      
                              </ul>
                            </div>
                        </div>
                      </div>
                    </Col>
                   
                    <Col md={3}>
                      <h5>Speciality Prints</h5>
                      <ul className="megamenuUl">
                        <li><Link className="navbarList" to="/door-hanger-printing">Printing of Announcements</Link></li>
                        <li><Link className="navbarList" to="/custom-note-cards">Printing of Greeting & Holiday Cards</Link></li>
                        <li><Link className="navbarList" to="/custom-pocket-folder">Printing of Invitations</Link></li>
                    <li><Link className="navbarList" to="/postcard-printing-and-designing">Printing of Note Cards</Link></li>
                    <li><Link className="navbarList" to="/postcard-printing-and-designing">Printing of Thank You Cards</Link></li>

                    <li><Link className="navbarList" to="/custom-pocket-folder">Printing of Postcards</Link></li>
                  
                      </ul>
                    </Col>
                    <Col md={3}>
                      <img
                        src="/menutop.jpg"
                        alt="Mega Menu "
                        className="img-fluid"
                      />
                    </Col>
                  </Row>
            
              </NavDropdown.Item>
            </NavDropdown>
        
            <Nav.Link as={Link} to="/about" className="menu-item">About</Nav.Link>
            <Nav.Link as={Link} to="/services" className="menu-item">Banners, Signs & Posters</Nav.Link>
            <NavDropdown title="Products" id="navbar-dropdown" className="mega-menu">
              <NavDropdown.Item>
             
                  <Row>
                    <Col md={3}>
                      <h5>Category 1</h5>
                      <ul className="megamenuUl">
                        <li><Link className="navbarList" to="/custom-flyers-printing-services">Custom flyers Printing Services</Link></li>
                        <li><Link className="navbarList" to="/certificate-printing-services">Certificate Printing Services</Link></li>
                        <li><Link className="navbarList" to="/business-card-printing">Business Card Printing</Link></li>
                        <li><Link className="navbarList" to="/school-copy-printing">School Copy Printing</Link></li>
                        <li><Link className="navbarList" to="/presentation-printing">Presentation Printing</Link></li>
                      </ul>
                    </Col>
                    <Col md={3}>
                      <h5>Category 2</h5>
                      <ul className="megamenuUl">
                        <li><Link className="navbarList" to="/custom-envelops">Custom Envelops</Link></li>
                        <li><Link className="navbarList" to="/custom-notepads">Custom Notepads</Link></li>
                        <li><Link className="navbarList" to="/stamps-and-embossers">Stamps and Embossers</Link></li>
                        <li><Link className="navbarList" to="/document-printing-and-binding">Document Printing &amp; Binding</Link></li>
                        <li><Link className="navbarList" to="/rack-card-design">Rack Card Design</Link></li>
                      </ul>
                    </Col>
                    <Col md={3}>
                      <h5>Category 3</h5>
                      <ul className="megamenuUl">
                        <li><Link className="navbarList" to="/door-hanger-printing">Door Hanger Printing</Link></li>
                        <li><Link className="navbarList" to="/custom-note-cards">Custom Note Cards</Link></li>
                        <li><Link className="navbarList" to="/custom-pocket-folder">Custom Pocket Folder</Link></li>
                    <li><Link className="navbarList" to="/postcard-printing-and-designing">Postcard Printing &amp; Designing</Link></li>
                      </ul>
                    </Col>
                    <Col md={3}>
                      <img
                        src="/menutop.jpg"
                        alt="Mega Menu "
                        className="img-fluid"
                      />
                    </Col>
                  </Row>
            
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/blogs" className="menu-item">Blogs</Nav.Link>

            <Nav.Link as={Link} to="/contact" className="menu-item">Contact</Nav.Link>

            {/* Mega Menu */}
         
          </Nav>

          {/* Phone and Email Icons on the Right Side */}
         
        </Navbar.Collapse>
      </Container>
    </Navbar> 
   </div>
  );
};

export default Header;