import React from "react";
import { Helmet } from "react-helmet-async";

const MetaManager = ({ title, description, keywords }) => {
  console.log("Meta Props Received:", { title, description, keywords }); // Log props

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
    </Helmet>
  );
};


export default MetaManager;
