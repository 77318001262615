import React from "react";
import "../components/TopHeader.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const TopHeader = () => {
  return (
    <div className="top-header">
      <div className="d-flex top-container">
        <div className="contact-details">
          <span>
            <FontAwesomeIcon icon={faPhone} />{" "}
            <a href="tel:+919990168777">+91 9990168777</a>
          </span>
          <span>
            <FontAwesomeIcon icon={faEnvelope} />{" "}
            <a href="mailto:wow@printingy.com">wow@printingy.com</a>
          </span>
         
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
