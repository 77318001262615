import React, { useState, useEffect } from "react";
import "./VideoBanner.css";

const VideoBanner = ({ videoSrc, posterImage, messages }) => {
  const [displayedText, setDisplayedText] = useState(""); // Current text to display
  const [messageIndex, setMessageIndex] = useState(0); // Current message index
  const [isErasing, setIsErasing] = useState(false); // Erasing or typing
  const typingSpeed = 100; // Speed of typing
  const erasingSpeed = 50; // Speed of erasing
  const pauseTime = 1500; // Pause time before erasing or switching

  useEffect(() => {
    const handleTyping = () => {
      const currentMessage = messages[messageIndex];

      if (isErasing) {
        // Erase text
        setDisplayedText((prev) => prev.slice(0, -1));
        if (displayedText.length === 0) {
          setIsErasing(false); // Switch to typing
          setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }
      } else {
        // Type text
        setDisplayedText((prev) => currentMessage.slice(0, prev.length + 1));
        if (displayedText === currentMessage) {
          setTimeout(() => setIsErasing(true), pauseTime); // Pause before erasing
        }
      }
    };

    const timer = setTimeout(handleTyping, isErasing ? erasingSpeed : typingSpeed);

    return () => clearTimeout(timer);
  }, [displayedText, isErasing, messageIndex, messages]);

  return (
    <div className="video-banner-container">
      <video
        className="video-banner"
        src={videoSrc}
        poster={posterImage}
        autoPlay
        loop
        muted
        playsInline
      />
      <div className="text-overlay">{displayedText}</div>
    </div>
  );
};

export default VideoBanner;
