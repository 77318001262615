import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const SchoolCopyPrinting = () => {

    const servicesFaqData = [
        { question: "What customisation options are available for copy printing?", answer: "Printingy offers a variety of customisation options for copy printing services, including paper size, type, and colour preferences. You can also choose binding styles for school copies or corporate documents. These options ensure that your copies meet your specific requirements for professional presentations or educational materials." },
        { question: "Does Printingy specialise in bulk copy printing?", answer: "Yes, Printingy focuses on copy printing in bulk to cater to businesses, schools, and organisations with large-scale needs. Bulk printing ensures consistent quality across all copies, making it ideal for marketing materials, training manuals, or school books. Our efficient processes and advanced technology guarantee high-quality results for all orders." },
        { question: "What industries benefit from copy-printing services?", answer: "Copy printing services are essential for various industries, including education, corporate offices, hospitality, and healthcare. Schools often use our school copy printing services for textbooks, workbooks, and exams, while businesses rely on us for reports, training documents, and promotional materials. Printingy provides tailored solutions to meet the unique needs of each sector." },
        { question: "What materials are used for copy printing?", answer: "We use high-quality materials for copy-printing service projects, including durable paper and eco-friendly inks. Options include standard and premium paper grades with matte or glossy finishes. These materials ensure that your printed copies are professional, long-lasting, and suitable for their intended purpose." },
        { question: "Can Printingy assist with layout design for printed copies?", answer: "Our team offers design assistance for school copy-printing services and corporate print needs. Whether you need help organising content, adding graphics, or ensuring proper formatting, we collaborate to create visually appealing and functional layouts. This service guarantees that your printed materials are effective and professional." },
        { question: "What is the typical turnaround time for bulk copy printing?", answer: "Turnaround times for copy printing in bulk depend on the size and complexity of your order. Standard bulk orders are typically completed within a few business days. For highly customised requests or exceptionally large volumes, we provide clear timelines and prioritise meeting your deadlines without compromising quality." },
        { question: "Are eco-friendly options available for copy printing?", answer: "Printingy is committed to sustainability and offers eco-friendly solutions for copy printing service projects. We use recyclable paper and non-toxic inks to minimise environmental impact. These options are perfect for organisations seeking sustainable printing practices without sacrificing quality." },
        { question: "What factors should I consider when ordering bulk copies?", answer: "Consider the purpose, audience, and distribution method when ordering copy printing in bulk. Selecting the right paper type, size, and binding options ensures your copies are functional and visually appealing. Printingy’s team guides the process to help you achieve the desired outcome for your materials." },
        { question: "How do I place an order for copy-printing services?", answer: "Placing an order is simple and efficient. Visit our website and choose your preferences for school copy or business copy printing services. Upload your files or share your requirements; our team will guide you through the process. We ensure a seamless experience from design approval to delivery." },
        { question: "What makes bulk copy printing cost-effective?", answer: "Bulk copy printing reduces per-unit costs, making it an economical choice for large-scale needs. Printingy delivers high-quality results at competitive prices by consolidating materials and streamlining production. Our copy printing in bulk services ensures exceptional value without compromising on quality." }
    ];
    
    
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "School Copy Printing",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
    <MetaManager 
  title="School Copy Printing Services in Bulk"
  description="Reliable copy printing service for businesses and schools. Bulk printing solutions tailored for school copies and corporate needs. High quality and timely delivery."
  keywords="Copy printing service,Copy printing in bulk,School copy-printing services"
/>


      <header className="services-header text-center py-5 serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Bulk Copy Printing Services</h1>
              <h2 className="text-muted">
              Professional copy printing tailored for schools and businesses
              </h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>
          Copy printing remains a fundamental need for businesses, educational institutions, and organisations. Printingy provides high-quality copy printing service solutions, specialising in copy printing in bulk for diverse requirements. We handle large-scale printing projects efficiently and professionally, from office documents to school copies.
          </p>
          <p>
          Our school copy printing services cater to educational institutions requiring consistent quality textbooks, notebooks, and other materials. For corporate clients, we offer flexible options for marketing materials, training documents, and operational print needs. With advanced printing technology and a commitment to precision, we ensure sharp text, vibrant images, and durable finishes in every order.
</p>
          <p>Whether you need standard black-and-white copies or full-colour custom prints, Printingy guarantees reliable and cost-effective services. Let us handle your bulk printing needs while you focus on what matters most.
          </p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3  rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">Reliable Bulk Copy Printing Solutions</h2>
                <h3 className="large-hed">High-quality, professional, and affordable copy printing services</h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Specialised copy printing service with premium quality results.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Efficient copy printing in bulk for large-scale projects.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Customised solutions for school copy printing services.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Durable materials and eco-friendly printing practices.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Vibrant colours and sharp text for professional output.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Affordable pricing with competitive bulk discounts.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Fast turnarounds with efficient order management systems.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by businesses, schools, and organisations nationwide.
                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Custom copy printing for businesses"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed">Premium Copies Delivered On Time</h2>
            <h3 className="small-hed">Focus on your goals while we manage your copy printing needs</h3>
            <p>At Printingy, we prioritise quality and efficiency in every copy printing service project. Whether you require copy printing in bulkfor a corporate event or school copy printing services for educational institutions, our advanced technology and expert team deliver exceptional results.</p>
            <p>Our streamlined processes ensure the timely delivery of bulk orders without compromising on quality. Trust Printingy for professional printing solutions that meet your deadlines and exceed your expectations.</p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default SchoolCopyPrinting;
