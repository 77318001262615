import React from "react";
import './CallToAction.css'
export default function CallToAction({ title, description }) {
  return (
   <div className="container cta-outer-div">
     <div className="ctacontainer row">
     <div className="col-md-6 ">
     <h2>{title}</h2>
     <p>{description}</p>
     </div>
     <div className="col-md-6 ctainner">
      <a className="cta-btn" href="#">Click Here</a>
      </div>
    </div>

    </div>
 
  );
}
