import React from "react";
import { Helmet } from "react-helmet";
import VideoBanner from "../components/Home/VideoBanner";
import Solutions from "../components/Home/solutions/Solutions";
import CallToAction from "../components/Home/CallToAction/CallToAction";
import WhoWeServe from "../components/Home/WhoWeServe/WhoWeServe";
import WhyUs from "../components/Home/WhyUs/WhyUs";
import AboutUs from "../components/Home/AboutUs/AboutUs";
import HomeCategories from "../components/Home/HomeCategories/HomeCategories";
import IconSection from "../components/IconSection/IconSection";
import FAQ from "../components/Home/Faq/Faq";
const Home = () => {
  const messages = [
    "Welcome to Our Website!",
    "Discover Amazing Experiences",
    "Your Journey Starts Here",
  ];
  return (
    <>
      <Helmet>
        <title>Home Page - Your Website</title>
        <meta name="description" content="This is the home page of your website" />
        <meta name="keywords" content="home, website, react" />
        <link rel="canonical" href="https://yourwebsite.com/home" />
        {/* Add schema and FAQ schema here */}
      </Helmet>
      
     <VideoBanner
        videoSrc="/bannerVideo.mp4"
        posterImage="/path-to-poster.jpg"
        messages={messages}
      />
     
      <Solutions />
      <AboutUs />
      <IconSection />
      <WhoWeServe />
      <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
      <WhyUs />
    
      <HomeCategories />
      <FAQ />
    </>
  );
};

export default Home;
