import React from "react";
import './WhyUs.css'
export default function WhyUs() {
    const icons = [
        {
            id: 1,
            Heading: "Unmatched Quality",
            description: "We pride ourselves on delivering exceptional quality in every print job. Our state-of-the-art printing technology ensures that every detail is captured perfectly, whether it’s for promotional materials, packaging, or corporate prints. You can trust that your materials will reflect the highest standards.",
            image: "/newicon.png", // Placeholder image URL
        },
        {
            id: 2,
            Heading: "Fast Turnaround Times",
            description: "We understand the importance of timely delivery. Our efficient processes and expert team ensure that you receive your printed materials on time, every time. Whether it's a last-minute event or a tight project deadline, we’ve got you covered.",
            image: "/newicon.png", // Placeholder image URL
        },
        {
            id: 3,
            Heading: "Cost-Effective Solutions",
            description: "Our competitive pricing ensures you get the best value for your investment without compromising on quality. We offer flexible pricing models to suit businesses of all sizes, ensuring cost-effective solutions for every print project.",
            image: "/newicon.png", // Placeholder image URL
        },
        {
            id: 4,
            Heading: "Expert Support",
            description: "Our team of experts is always here to guide you through every step of the process. From choosing the right materials to helping with design and finishing options, we provide personalized support to make your project a success.",
            image: "/newicon.png", // Placeholder image URL
        },
        {
            id: 5,
            Heading: "Sustainable Practices",
            description: "We are committed to sustainable printing practices. By using eco-friendly materials and methods, we help reduce the environmental impact of our production processes, ensuring a greener future for everyone.",
            image: "/newicon.png", // Placeholder image URL
        },
        {
            id: 6,
            Heading: "Custom Solutions",
            description: "Every client has unique needs, and we’re here to provide tailored printing solutions. Whether you need custom sizes, special finishes, or unique materials, we can adapt to your specific requirements and deliver personalized results.",
            image: "/newicon.png", // Placeholder image URL
        },
    ];

    return (
        <div className="container">
            <div className=" whyus-outer">
          <div className="whyus-inner">
          <div className="container whyus-topheading">
          <h2 className="text-center ">Why Us</h2>
          <p>We pride ourselves on delivering exceptional quality in every print job. Our state-of-the-art printing technology ensures that every detail is captured perfectly, whether it’s for promotional materials, packaging, or corporate prints. You can trust that your materials will reflect the highest standards.</p>
          </div>
            <div className="row whyus-row">
                {icons.map((icon) => (
                    <div key={icon.id} className="col-md-4 dropdown-container">
                        <div className="whyus-item">
                           
                            <div className=" whyus-border">
           <div className="color-transition-div">
           <img src={icon.image} alt={icon.Heading} className="whyus-image " />
           </div>
</div>
                            
                            <h5 className="whyus-heading">{icon.Heading}</h5>
                            <p className="whyus-para">{icon.description}</p>
                

                        </div>
                    </div>
                ))}
            </div>
          </div>
          
        </div>
        </div>
    );
}
