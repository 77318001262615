import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const CertificatePrintingServices = () => {

  const servicesFaqData = [
    { question: "How can I customise certificates for my organisation? ", answer: "Customising certificates with Printingy is easy and flexible. You can share your branding details, including logos, colours, and text, and we will incorporate these elements into the design. Our team can also create unique layouts or suggest ideas based on your requirements. This ensures that each certificate effectively reflects your organisation’s identity and purpose." },
    { question: "Does Printingy provide certificate printing in bulk for events?", answer: "Yes, we specialise in certificate printing in bulk, catering to events, schools, and corporate programmes. Our bulk services are designed to handle large orders efficiently while maintaining consistent quality. Whether it’s for a workshop, seminar, or graduation, we ensure timely delivery and professional results for all certificates." },
    { question: "What materials do you use for certificate printing? ", answer: "We use high-quality materials for all our certificate designs and printing projects, including premium paper and eco-friendly inks. You can choose between matte or glossy finishes depending on your preferences. We focus on durability and visual appeal, ensuring the certificates look professional and last long." },
    { question: "Can I get custom designs for certificates?", answer: "Absolutely! Our print custom certificate services include complete customisation, from design to finishing. Share your vision, and our design team will create a layout that aligns with your goals. This includes options for fonts, colours, and graphic elements to make the certificates unique and impactful." },

    { question: "What is the minimum order quantity for certificate printing? ", answer: "We do not enforce strict minimum order requirements. Whether you need a single certificate or certificate printing in bulk, we cater to all requests. This flexibility ensures that our services are accessible and tailored to your needs, regardless of the order size." },
    { question: "How long does it take to print and deliver certificates? ", answer: "Turnaround times for certificate printing vary depending on the size and complexity of the order. For standard requests, we deliver within a few business days. Larger or customised orders may take slightly longer, but we always strive to meet your deadlines while maintaining quality." },
    { question: "Do you offer eco-friendly certificate printing options?", answer: "Yes, sustainability is a key focus at Printingy. Our certificate designs and printing services use recyclable materials and non-toxic inks to minimise environmental impact. We aim to combine environmental responsibility with exceptional print quality for all orders." },
    { question: "Can I review the certificate design before printing? ", answer: "Yes, we provide a proofing process to ensure your satisfaction with the design before proceeding with certificate printing. Once the design is finalised, we share a digital proof for your approval. This ensures that the final product matches your expectations perfectly." },
    { question: "What industries does Printingy serve for certificate printing?", answer: "Printingy caters to various industries, including education, corporate, healthcare, and events. Our certificate printing in bulk solutions is ideal for schools, universities, training programmes, and business events. Regardless of the industry, we provide customised certificates to meet your requirements." },
    { question: "How do I place an order for certificate printing?  ", answer: "Placing an order is simple and efficient. Visit our website and select your preferred options for printing custom certificates. Upload your design or share your requirements, and our team will guide you through the process. We ensure a hassle-free experience from start to finish." }
  ];
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Certificate Printing Services",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
       <MetaManager 
  title="Certificate Printing Services for Businesses and Events"
  description="Professional certificate printing, including bulk orders and custom designs. Get top-quality certificate designs and printing for your needs."
  keywords="Certificate printing,Certificate printing in bulk,Certificate designs and printing,Print custom certificates"
/>
      <header className="services-header text-center py-5  serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Professional Certificate Printing Services</h1>
              <h2 className="text-muted">
              Custom certificate designs and bulk printing tailored for any occasion
              </h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>
          Certificates are essential for recognising achievements, rewarding excellence, or commemorating milestones. Printingy offers professional certificate printing services designed to meet your specific needs. Our expertise covers everything from crafting high-quality certificate designs and printing to handling certificate printing in bulk for schools, businesses, and organisations.

          </p>
          <p>
          With print custom certificates, you can personalise each piece with logos, colours, and names to align perfectly with your branding or event theme. Whether for academic achievements, employee recognition, or event participation, our certificates are produced using premium materials and state-of-the-art technology. We guarantee vibrant prints, accurate text, and durable finishes to leave a lasting impression.
          </p>
          <p>Our streamlined process ensures fast turnarounds without compromising on quality. Trust Printingy delivers certificates that reflect professionalism, precision, and purpose for every occasion.</p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3  rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">Customised Certificate Printing Solutions</h2>
                <h3 className="large-hed">High-quality, reliable, and affordable certificate printing services</h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Customisable designs tailored to your specific needs.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Expertise in certificate printing in bulk for large-scale events.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Affordable pricing with no compromise on quality.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Advanced printing technology for sharp details and vibrant colours.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Options to print custom certificates with unique branding elements.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Use of eco-friendly materials for sustainable printing practices.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    On-time delivery with efficient order management systems.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by schools, businesses, and organisations across various industries.
                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Bulk certificate printing options"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed"> Certificate Printing with Excellence</h2>
            <h3 className="small-hed">Focus on your goals while we handle your printing needs</h3>
            <p>
            Printingy combines industry-leading expertise with advanced technology to deliver superior certificate designs and printing services. Whether you require certificate printing in bulk or smaller customised orders, we ensure every project meets the highest standards of quality and precision.

            </p>
            <p>
            Our commitment to timely delivery ensures your certificates are ready when you need them without any delays. Let us handle the printing, so you can focus on celebrating achievements and milestones with certificates that truly stand out.
            </p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default CertificatePrintingServices;
