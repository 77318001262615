import React from 'react';
import './FaqItem.css'
const FAQItem = ({ question, answer, isOpen, onToggle }) => {
  return (
   <div className='outer-faq'>
     <div className="faq-item">
      <h3 className="faq-question" onClick={onToggle}>
        {question}
        <span className="toggle-icon">{isOpen ? '-' : '+'}</span>
      </h3>
      {isOpen && <p className="faq-answer">{answer}</p>}
    </div>
   </div>
  );
};

export default FAQItem;
