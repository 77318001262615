import React, { useState } from "react";
import { Helmet } from "react-helmet-async"; // For dynamic meta tags and structured data
import "./Services.css";

const Faq = ({ faqData, children, canonicalUrl }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Use `faqData` if provided; otherwise, render children
  const faqContent = faqData || React.Children.toArray(children);

  // Generate the FAQ schema dynamically
  const faqSchema = faqContent.map((faq) => ({
    "@type": "Question",
    "name": faq.question,
    "acceptedAnswer": {
      "@type": "Answer",
      "text": faq.answer,
    },
  }));

  return (
    <div className="faq-top container">
      {/* Dynamic Schema and Canonical URL Injection */}
      <Helmet>
        {/* Dynamic Canonical URL */}
        <link rel="canonical" href={canonicalUrl || window.location.href} />

        {/* FAQ Schema */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: faqSchema,
          })}
        </script>
      </Helmet>

      <h2 className="faq-heads text-center">Frequently Asked Questions</h2>
      <div className="faq-lists">
        {faqContent.map((faq, index) => (
          <div className="faq-bgs" key={index}>
            <div
              className={`faq-items ${openIndex === index ? "open" : ""}`}
              onClick={() => toggleFaq(index)}
            >
              {faqData ? (
                // Render from `faqData` if present
                <>
                  <h4 className="faq-questions">{faq.question}</h4>
                  {openIndex === index && (
                    <div className="faq-answers">{faq.answer}</div>
                  )}
                </>
              ) : (
                // Otherwise, render `children`
                faq
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
