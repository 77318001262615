import React from "react";
import './HomeCategories.css';  // Optionally, for styling

export default function HomeCategories() {
    const categoryCard = [
        {
            id: 1,
            images: [
                { src: "/sol1.jpg", heading: "Custom Printing Solutions", description: "We offer a wide range of custom printing services tailored to your specific needs. Whether it’s business cards, brochures, or promotional materials, our solutions are designed to meet your branding and marketing goals with precision." },
            ],
        },
        {
            id: 2,
            images: [
                { src: "/sol2.jpg", heading: "High-Quality Materials", description: "Our materials are sourced for their durability and quality. From eco-friendly options to premium textures, we ensure that every print job not only looks great but also stands the test of time." },
            ],
        },
        {
            id: 3,
            images: [
                { src: "/sol3.jpg", heading: "Large Format Printing", description: "Ideal for banners, signage, and posters, our large format printing service provides high-definition prints in various sizes. Whether for events, retail, or exhibitions, we offer flexible solutions to make a big impact." },
            ],
        },
        {
            id: 4,
            images: [
                { src: "/sol4.jpg", heading: "Packaging Solutions", description: "We specialize in high-quality packaging solutions that showcase your product in the best light. From custom boxes to product labels, we ensure your packaging is both functional and attractive, enhancing your brand’s appeal." },
            ],
        },
        {
            id: 5,
            images: [
                { src: "/sol4.jpg", heading: "Personalized Stationery", description: "Our personalized stationery services include letterheads, notepads, and envelopes that align perfectly with your business identity. Designed for professionalism, our stationery solutions are great for corporate or personal use." },
            ],
        },
        {
            id: 6,
            images: [
                { src: "/sol4.jpg", heading: "Promotional Materials", description: "We create promotional materials that engage and attract your target audience. From flyers and postcards to pens and mugs, our print solutions help spread your brand’s message effectively across multiple channels." },
            ],
        }
     
    ];

    return (
        <div className="container mt-80">
            <h2 className="text-center home-cat-heading">Categories We Cover</h2>
            <div className="row">
                {categoryCard.map((card) => (
                    <div key={card.id} className="col-md-4">
                        <div className=" cat-outer-div">
                           <div className=" category-card">
                             {/* Displaying only the first image in the images array */}
                             <div className="cat-image-item">
                             <h5 className="cat-inner-hed">{card.images[0].heading}</h5>
                                <img src={card.images[0].src} alt={card.images[0].heading} className="img-fluid" />
                               
                                <p className="cat-inner-para">{card.images[0].description}</p>
                            </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
