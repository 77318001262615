import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const StampsandEmbossers = () => {

  const servicesFaqData = [
    { question: "What customisation options are available for stamps and embossers?", answer: "Printingy offers a wide range of customisation options for custom stamps for business and embossers. You can include logos, company names, addresses, or unique designs tailored to your needs. We also provide various stamp types, including self-inking and pre-inked options, as well as manual embossers. These customisation choices ensure your stamps and embossers align with your branding and operational requirements." },
    { question: "Can Printingy handle bulk orders for custom stamps and embossers?", answer: "Yes, Printingy specialises in bulk orders for customised stamps and embossers, catering to businesses and organisations with large-scale needs. Whether for branding, documentation, or packaging, we maintain consistent quality across all products in your order. Our bulk services are ideal for businesses with multiple departments or locations requiring uniform tools." },
    { question: "What industries benefit from custom stamps and embossers?", answer: "Custom stamps and embossers are widely used across industries such as legal services, real estate, retail, education, and corporate offices. Our logo business stamps are particularly beneficial for branding and efficient document handling, while embossers add a professional touch to certificates, contracts, and packaging. Whatever your industry, Printingy ensures our products meet your specific needs." },
    { question: "What materials are used for stamps and embossers?", answer: "We use high-quality, durable materials for our custom stamps for business and embossers to ensure long-lasting performance. Our stamps feature precision-moulded rubber for sharp impressions and sturdy casings for frequent use. For embossers, we use metal components designed for durability and clean, professional impressions. These materials guarantee reliable and professional results for your business needs." },
    { question: "Can Printingy assist with logo design for stamps?", answer: "Yes, our customised stamps service includes design assistance for logos or other elements. If you don’t have a ready design, our team can help create or refine one to suit your brand. From layout optimisation to ensuring clean impressions, we collaborate with you to produce stamps or embossers that meet your expectations." },
    { question: "What is the typical turnaround time for bulk orders?", answer: "Turnaround times for logo business stamps and embossers depend on the size and complexity of the order. Standard bulk orders are typically completed within a few business days. We always prioritise meeting deadlines while maintaining exceptional quality, ensuring your products are ready when you need them." },
    { question: "Are eco-friendly options available for stamps and embossers?", answer: "Yes, Printingy is committed to sustainability. We offer eco-friendly materials for custom stamps for business, including recyclable plastics and non-toxic inks. These options allow businesses to maintain professional standards while reducing environmental impact. Eco-friendly embossers with durable yet sustainable components are also available." },
    { question: "How do I ensure my stamp or embosser design is effective?", answer: "An effective stamp or embosser design should be clear, concise, and aligned with your branding. Our team collaborates with you to optimise elements like text size, logo placement, and layout for sharp impressions. This ensures that your logo business stamps or embossers are both functional and visually impactful in every use." },
    { question: "What factors should I consider when ordering bulk custom stamps?", answer: "When placing a bulk order for custom stamps for business, consider the types of stamps needed (self-inking, pre-inked, or manual), the design elements, and the intended use. Additionally, ensure that the materials and finishes match your durability requirements. Printingy provides guidance at every step to ensure your order meets all your expectations." },
    { question: "How do I place an order for stamps and embossers?", answer: "Placing an order is simple and efficient. Visit our website and choose your preferences for customised stamps or embossers. Upload your design or share your requirements, and our team will handle the rest. We ensure a seamless process from design approval to delivery." }
];

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Custom Envelopes",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
       <MetaManager 
  title="Custom Stamps and Embossers for Businesses"
  description="Get custom stamps for business, including logo business stamps and embossers. Bulk orders for professional, durable customised stamps."
  keywords="Custom stamps for business,Order customised stamps ,Logo business stamps"
/>
      <header className="services-header text-center py-5  serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Custom Stamps and Embossers Printing</h1>
              <h2 className="text-muted">
              Professional logo business stamps for branding and documentation
              </h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>
          Custom stamps and embossers are essential tools for businesses, offering efficiency, professionalism, and branding in everyday operations. Printingy specialises in custom stamps for business, including options for text, logos, and personalised designs. Our stamps are durable, precise, and designed for frequent use in offices, retail stores, and other professional environments.
          </p>
        
          <p>
          For businesses looking to make a statement, we also provide custom embossers that add a professional touch to documents, certificates, and packaging. You can order customised stamps or embossers in bulk, ensuring consistency across your team or operations. With options for self-inking, pre-inked, and manual stamps, we cater to all your specific requirements.
          </p>
          <p>Using advanced printing and manufacturing techniques, we ensure high-quality logo business stamps and embossers that enhance your brand identity. Whether for branding, documentation, or security purposes, Printingy delivers reliable and efficient stamping solutions.</p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3  rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">Reliable Custom Stamp and Embosser Solutions</h2>
                <h3 className="large-hed">High-quality, durable, and professional stamps and embossers</h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Customised designs for custom stamps for business branding.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Precision-crafted embossers for professional document sealing.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Bulk ordering options for consistent use across teams or locations.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Durable materials for long-lasting performance and frequent use.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Advanced printing and crafting techniques for sharp impressions.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Affordable pricing for bulk orders without compromising quality.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Fast turnarounds and efficient order management.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by businesses, organisations, and institutions nationwide.
                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Professional logo business stamps"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed">High-Quality Stamps and Embossers Delivered On Time</h2>
            <h3 className="small-hed">Focus on operations while we handle your custom stamp needs</h3>
            <p>
            Printingy is committed to providing high-quality custom stamps for business and embossers that meet your exact specifications. Whether you need logo business stamps for branding or embossers for professional documentation, we ensure precision and durability in every product.</p>
            <p>Our streamlined processes and advanced technology enable us to handle bulk orders efficiently, delivering on time without compromising quality. Trust Printingy for stamping and embossing solutions that elevate your brand and streamline your operations.</p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default StampsandEmbossers;
