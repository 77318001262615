import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const BusinessCardPrinting = () => {

  const servicesFaqData = [
    { question: "What customisation options are available for business cards?", answer: "Printingy provides extensive customisation options for business cards, allowing you to select sizes, finishes, and materials. Our visiting card design and printing team can create unique layouts, incorporating logos, fonts, and colour schemes that align with your brand identity. Additional features like embossing, foil stamping, and rounded corners can further enhance the design. This flexibility ensures your business cards stand out in any professional setting." },
    { question: "Can Printingy handle bulk business card printing orders?", answer: "Yes, we specialise in both small and bulk business card printing orders. Our advanced printing technology ensures consistent quality across all cards, regardless of the order size. Bulk orders are ideal for organisations with large teams or frequent networking needs. We provide efficient processing and timely delivery, meeting your requirements without compromising quality." },
    { question: "What materials are used for business card printing?", answer: "We use premium materials for all visiting card printing projects, including high-quality card stock. Options include standard matte, glossy, textured, and eco-friendly paper. These materials are chosen for durability and visual appeal, ensuring your cards maintain their professional look over time. Our eco-friendly options also allow businesses to align with sustainable practices." },
    { question: "Does Printingy provide design assistance for business cards?", answer: "Yes, our visiting card design and printing services include professional design assistance. Whether you have a design concept or need a layout from scratch, our team collaborates to create visually appealing and effective business cards. We combine aesthetics with functionality, ensuring the final design reflects your brand values." },

    { question: "What is the typical turnaround time for business card printing?", answer: "Turnaround times for business card printing depend on the order size and customisation requirements. Standard orders are completed within a few business days, while bulk or highly customised designs may take slightly longer. We prioritise meeting deadlines and communicate timelines upfront to ensure your needs are met efficiently." },
    { question: "Does Printingy offer eco-friendly business card printing options?", answer: "Yes, Printingy is committed to sustainable practices. Our visiting card printing service includes options for recyclable paper and eco-friendly inks. These materials maintain the same premium quality while minimising environmental impact. This allows you to showcase your professionalism and environmental responsibility simultaneously." },
    { question: "What industries does Printingy cater to for business card printing?", answer: "Printingy caters to professionals and businesses across various industries, including corporate, retail, real estate, healthcare, and creative sectors. Our business card printing solutions are tailored to suit individual or organisational branding needs. Regardless of your industry, we create business cards that help you make a strong impression." },
    { question: "Can I order business cards with specific finishes?", answer: "We offer multiple finishing options for visiting card printing, including matte, glossy, textured, and embossed finishes. Each finish adds a unique touch, allowing you to customise the appearance of your cards. These options ensure your business cards reflect your style and professionalism effectively." },
    { question: "How do I place an order for business card printing?", answer: "Placing an order is simple and efficient. Visit our website and choose your options for visiting card design and printing. Upload your design or share your requirements, and our team will guide you through the process. We ensure a seamless experience from order placement to delivery." },
    { question: "What is the minimum order quantity for business card printing? ", answer: "We do not have strict minimum order requirements for business card printing. Whether you need a single batch for personal use or bulk orders for a large team, we accommodate requests of all sizes. This flexibility ensures our services meet your unique needs." }
  ];
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Business Card Printing",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
    <MetaManager 
  title="Business Card Printing and Design Services"
  description="Professional business card printing, visiting card printing, and custom visiting card design and printing services for businesses."
  keywords="Business card printing,Visiting card printing,Visiting card printing service,Visiting card design and printing"
/>


      <header className="services-header text-center py-5  serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Professional Business Card Printing Services</h1>
              <h2 className="text-muted">
              Custom visiting card design and printing for lasting impressions 
              </h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>
          Business cards are essential for creating a strong first impression and building professional relationships. At Printingy, we specialise in business card printing that combines creativity with premium quality. Our services include visiting card printing and custom visiting card design and printing to meet your specific branding requirements.
          </p>
          <p>
          We offer a variety of card types, including matte, glossy, textured, and embossed finishes, ensuring your cards stand out. Our visiting card printing service allows for complete customisation, including logo placement, colour schemes, and unique designs that reflect your brand identity. Whether you need a small batch for a boutique business or large-scale orders for corporate teams, Printingy guarantees precise and professional results.</p>
          <p>Using state-of-the-art printing technology, we deliver business cards that are durable, visually appealing, and tailored to your needs. Trust Printingy to create business cards that leave a lasting impact on your clients and colleagues.
          </p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3 rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">Customised Visiting Card Printing Solutions</h2>
                <h3 className="large-hed">Reliable, professional, and affordable business card printing services </h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    High-quality business card printing with various finishes.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Customised visiting card printing for unique branding.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Expert visiting card design and printing to match your style.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Affordable pricing for small and bulk orders.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Eco-friendly materials for sustainable printing practices.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Sharp prints, vibrant colours, and premium textures.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Fast turnarounds and efficient order management.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by professionals, businesses, and startups nationwide.
                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Business card printing solutions"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed">Premium Business Cards Delivered On Time</h2>
            <h3 className="small-hed">Focus on networking while we manage your business card printing needs</h3>
            <p>
            At Printingy, we understand the importance of professional and timely business card printing. Our visiting card printing service is designed to provide flawless results, ensuring every card meets your expectations. Whether you need a sleek design or a bold statement card, we deliver quality and precision in every project.
            </p>
            <p>
            Our streamlined process guarantees timely delivery, even for bulk orders. With Printingy, you can focus on growing your network while we ensure your business cards effectively reflect your professionalism and brand.
            </p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default BusinessCardPrinting;
