import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const CustomPocketFolder = () => {

    const servicesFaqData = [
        { question: "What customisation options are available for pocket folders?", answer: "Printingy offers various customisation options for pocket folder printing to meet your unique needs. You can select sizes, materials, and finishes to align with your brand. Our pocket folder designs for businesses allow you to include logos, taglines, and custom layouts. These options ensure your folders are practical, visually appealing, and professional." },
        { question: "Can Printingy handle bulk presentation folder printing orders?", answer: "Yes, Printingy specialises in bulk presentation folder printing, catering to businesses with large-scale needs. Bulk printing ensures consistent quality and cost-effective production for companies requiring folders for multiple departments or large events. Our advanced printing technology and efficient processes guarantee timely delivery for all bulk orders." },
        { question: "What industries benefit from custom pocket folders?", answer: "Custom pocket folders are widely used across corporate offices, educational institutions, real estate, and event management industries. Businesses use them for presentations, proposals, and promotional materials. Printingy provides pocket folder designs for businesses tailored to the unique requirements of each sector, ensuring professional and impactful results." },
        { question: "What materials are used for pocket folder printing?", answer: "We use high-quality materials for pocket folder printing, including durable card stock and eco-friendly options. Finishes such as matte, glossy, or textured are available to enhance the appearance and longevity of your folders. These materials ensure your folders are sturdy, professional, and capable of protecting your important documents." },
        { question: "Does Printingy assist with pocket folder design?", answer: "Yes, our pocket folder designs for businesses include professional design assistance. Whether you have a specific vision or need creative input, our team collaborates with you to create engaging and practical layouts. From logo placement to colour schemes, we ensure every detail is optimised for functionality and branding." },
        { question: "What is the typical turnaround time for pocket folder printing?", answer: "Turnaround times for presentation folder printing depend on the size and complexity of your order. Standard bulk orders are typically completed within a few business days. For highly customised designs or large quantities, we provide clear timelines and prioritise meeting your deadlines without compromising quality." },
        { question: "Are eco-friendly options available for pocket folders?", answer: "Yes, Printingy is committed to sustainability and offers eco-friendly solutions for pocket folder printing. We use recyclable paper, non-toxic inks, and sustainable production practices. These options are ideal for businesses that want to maintain environmental responsibility while producing premium-quality folders." },
        { question: "What factors should I consider when ordering bulk pocket folders?", answer: "When placing a bulk order for pocket folder designs for business, consider the folder size, material type, and intended use. Choosing the right customisation options, such as logo placement and colour schemes, ensures your folders align with your branding. Printingy’s team guides to help you create folders that are both functional and visually appealing." },
        { question: "How do I place an order for pocket folder printing?", answer: "Placing an order is simple and efficient. Visit our website and choose your preferences for pocket folder designs for businesses. Upload your design files or share your requirements, and our team will guide you through the process. We ensure a seamless experience from design approval to delivery." },
        { question: "What is the best use case for custom pocket folders?", answer: "Custom pocket folders are ideal for organising and presenting materials professionally. They are commonly used for proposals, client meetings, event promotions, and educational materials. Printingy’s presentation folder printing services ensure your folders are designed to maximise functionality while making a strong visual impression." }
    ];
    
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Pocket Folder Printing",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
    <MetaManager 
  title="Custom Pocket Folder Printing Services"
  description="Professional pocket folder printing and presentation folder printing for businesses. Bulk printing with customised pocket folder designs for business needs."
  keywords="Pocket folder printing,Presentation folder printing,Pocket folder designs for business"
/>


      <header className="services-header text-center py-5  serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Custom Pocket Folder Printing Services</h1>
              <h2 className="text-muted">
              Professional pocket folder designs for business branding and organisation
              </h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>
          Pocket folders are essential for businesses that require organised and professional presentation materials. Printingy offers premium-quality pocket folder printing services tailored to meet your specific needs. From sleek presentation folder printing for meetings to customised pocket folder designs for business, we provide solutions that combine style and functionality.
          </p>
          <p>
          Our folders are perfect for storing brochures, business cards, and other essential documents while prominently showcasing your branding. We offer various options to suit your preferences, including different sizes, materials, and finishes. Our bulk printing services ensure consistent quality and timely delivery, whether for internal use or client presentations.</p>
          <p>With advanced printing technology and durable materials, Printingy delivers folders that elevate your brand while keeping your materials secure and well-organised. Trust us to provide professional pocket folders that make a lasting impression.
          </p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3  rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">High-Quality Presentation Folder Printing</h2>
                <h3 className="large-hed">Affordable and reliable custom pocket folder printing services </h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Customised pocket folder designs for business with premium finishes.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Professional presentation folder printing for meetings, proposals, and events.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Bulk printing services with consistent quality across all folders.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Eco-friendly materials for sustainable printing solutions.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Durable paper and sturdy construction for long-term use.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Affordable pricing with fast turnarounds and efficient order management.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by businesses, educational institutions, and event organisers.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Advanced printing technology for sharp branding and vibrant colours.
                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Presentation folder printing solutions"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed">Premium Business Cards Delivered On Time</h2>
            <h3 className="small-hed">Focus on networking while we manage your business card printing needs</h3>
            <p>
            At Printingy, we understand the importance of professional and timely business card printing. Our visiting card printing service is designed to provide flawless results, ensuring every card meets your expectations. Whether you need a sleek design or a bold statement card, we deliver quality and precision in every project.
            </p>
            <p>
            Our streamlined process guarantees timely delivery, even for bulk orders. With Printingy, you can focus on growing your network while we ensure your business cards effectively reflect your professionalism and brand.
            </p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default CustomPocketFolder;
