import React from "react";
import "./IconSection.css"; // Optional: Include your CSS for styling

export default function IconSection() {
  const IconText = [
    {
      id: 1,
      heading: "My Heading 1",
      icon: 'https://www.office.fedex.com/media/wysiwyg/flexibility-icon-55x55.png',
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    },
    {
      id: 2,
      heading: "My Heading 2",
      icon: 'https://www.office.fedex.com/media/wysiwyg/Building-icon-55x55.png',
      description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
      id: 3,
      heading: "My Heading 3",
      icon: 'https://www.office.fedex.com/media/wysiwyg/nbc-icon-55x55.png',
      description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
    },
  ];

  return (
    <div className="container icon-section">
      <div className="row">
        {IconText.map((item) => (
          <div key={item.id} className="col-md-4 text-center mb-4">
            <div className="icon-card">
              <img
                src={item.icon}
                alt={item.heading}
                className="icon-image"
              />
              <h5 className="icon-heading">{item.heading}</h5>
              <p className="icon-description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
