import React from "react";
import "../components/Contact/Contact.css";

const ContactPage = () => {
  return (
    <div className="contact-page py-5">
      <div className="container">
        <h1 className="text-center mb-5 page-title">Get in Touch</h1>
        <div className="row">
              {/* Contact Information */}
          <div className="col-lg-6">
            <div className="contact-info p-4 shadow">
              <h2 className="info-title mb-4">Contact Information</h2>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <i className="fas fa-phone me-3"></i>
                  <a className="contactLinks" href="tel:999 01 58 777">(+91) 999 01 58 777</a>
                  
                </li>
                <li className="mb-3">
                  <i className="fas fa-envelope me-3"></i>
                  <a className="contactLinks" href="mailto:vineet@giftingy.com"> vineet@giftingy.com</a>
                 
                </li>
                <li className="mb-3">
                  <i className="fas fa-map-marker-alt me-3"></i>
                  <a className="contactLinks" href="#">301C , Supermart-2 Gurugram</a>
                </li>
              </ul>
              <h3 className="mt-4 mb-3">Follow Us</h3>
              <div className="social-icons">
                <a href="#" className="me-3">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="#" className="me-3">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#" className="me-3">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          {/* Contact Form */}
          <div className="col-lg-6">
            <div className="form-wrapper p-4 shadow">
              <h2 className="form-title mb-4">Send Us a Message</h2>
              <form>
  <div className="mb-3">
    <label htmlFor="name" className="form-label">
      
    </label>
    <input
      type="text"
      className="form-control"
      id="name"
      placeholder="Your Name"
      required
    />
  </div>

  <div className="mb-3">
    <label htmlFor="email" className="form-label">
     
    </label>
    <input
      type="email"
      className="form-control"
      id="email"
      placeholder="youremail@example.com"
      required
    />
  </div>

  <div className="mb-3">
    <label htmlFor="phone" className="form-label">
    
    </label>
    <input
      type="tel"
      className="form-control"
      id="phone"
      placeholder="Your Phone Number"
      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
      title="Format: 123-456-7890"
      required
    />
  </div>

  <div className="mb-3">
    <label htmlFor="message" className="form-label">
    
    </label>
    <textarea
      className="form-control"
      id="message"
      rows="5"
      placeholder="Write your message here..."
      required
    ></textarea>
  </div>

  <button type="submit" className="btn btn-primary w-100">
    Send Message
  </button>
</form>

            </div>
          </div>

        
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
