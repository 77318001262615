import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const CustomNoteCards = () => {

    const servicesFaqData = [
        { question: "What customisation options are available for note cards?", answer: "Printingy offers a variety of customisation options for note card printing to meet your unique needs. You can select sizes, paper types, finishes, and layouts to match your preferences. Our custom note card designing services also allow you to add logos, images, and specific branding elements. These options ensure your note cards effectively align with your professional or personal objectives." },
        { question: "Does Printingy offer bulk note cards with envelopes printing?", answer: "Yes, Printingy specialises in bulk orders for note cards with envelopes printing, ensuring consistent quality across all pieces. Bulk printing is ideal for businesses, events, and organisations needing large quantities of note cards for communication or branding purposes. Our advanced printing systems and efficient processes ensure timely delivery for all bulk orders." },
        { question: "What industries benefit from custom note cards?", answer: "Custom note cards are widely used across corporate offices, event management, hospitality, and education industries. Businesses use them for thank-you notes, invitations, and client communication, while event organisers and institutions utilise them for formal announcements. Printingy provides note card printing solutions tailored to the specific needs of each industry, ensuring professional and impactful results." },
        { question: "What materials are used for note card printing?", answer: "We use high-quality materials for custom note card designing and printing, including premium card stock and eco-friendly paper options. Finishes such as matte, glossy, or textured are available to enhance the aesthetic appeal of your note cards. These materials ensure durability and a professional look, making your note cards ideal for various occasions." },
        { question: "Does Printingy provide design assistance for note cards?", answer: "Yes, our custom note card designing services include professional design assistance. Whether you have a specific design in mind or need creative input, our team collaborates with you to create visually appealing layouts. From selecting colours to arranging text and logos, we ensure every detail is optimised for the best results." },
        { question: "What is the typical turnaround time for note card printing?", answer: "Turnaround times for note card printing depend on the order size and level of customisation. Standard bulk orders are typically completed within a few business days. For highly customised designs or large quantities, we provide clear timelines and prioritise meeting your deadlines without compromising quality." },
        { question: "Are eco-friendly options available for note card printing?", answer: "Yes, Printingy is committed to sustainability and offers eco-friendly note card printing solutions. We use recyclable materials and non-toxic inks to reduce environmental impact while maintaining premium quality. These options are perfect for businesses and individuals seeking sustainable printing solutions." },
        { question: "What factors should I consider when ordering bulk note cards?", answer: "Consider the purpose, audience, and design elements when ordering bulk note cards with envelopes printing. The right paper type, size, and finishing options are essential to create a professional and appealing product. Printingy’s team guides the process to ensure your order meets your specific goals effectively." },
        { question: "How do I place an order for note card printing?", answer: "Placing an order is simple and efficient. Visit our website and choose your preferences for note card designing and printing. Upload your design files or share your customisation requirements, and our team will guide you through the process. We ensure a seamless experience from design approval to delivery." },
        { question: "What is the minimum order for note card printing?", answer: "Printingy does not focus on single small orders, but we specialise in bulk note card printing for businesses and events. This ensures cost-effective production and consistent quality for large-scale requirements. Contact us for customised solutions that fit your needs." }
    ];
    
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Custom Note Cards",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
    <MetaManager 
  title=" Custom Note Cards and Envelopes Printing Services"
  description="Professional note card printing with custom designs. Bulk printing of note cards with envelopes for businesses and events."
  keywords="Note cards printing, Custom note card designing,Note cards with envelopes printing"
/>


      <header className="services-header text-center py-5  serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Custom Note Cards Printing Services </h1>
              <h2 className="text-muted">
              Professional note card designing and printing for businesses
              </h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>
          Note cards are versatile and thoughtful communication tools, perfect for personal messages, corporate branding, or event invitations. Printingy specialises in note card printing with fully customisable options tailored to your brand’s requirements. Our services include custom note card design and note card with envelope printing, ensuring a seamless and professional presentation.
          </p>
          <p>
          Whether you need a simple and elegant design or vibrant, branded visuals, our printing solutions cater to all your business needs. From small notes of appreciation to formal corporate correspondence, our note cards are crafted with premium materials for durability and aesthetic appeal.
          </p>
          <p>With Printingy, you can customise the size, layout, and paper type to perfectly match your goals. We also handle bulk orders, ensuring consistent quality across all prints while efficiently meeting your deadlines.
          </p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3  rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">High-Quality Custom Note Cards</h2>
                <h3 className="large-hed">Affordable and reliable note card printing services</h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Customised note cards designed and tailored to your branding.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Professional note cards with envelopes printing for a complete package.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Durable and eco-friendly materials for premium results.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Bulk printing services with consistent quality across all prints.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Flexible design options for various corporate or personal needs.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Affordable pricing with fast turnarounds and efficient order management.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by businesses, event organisers, and educational institutions.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Advanced printing technology for sharp details and vibrant designs.
                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Business card printing solutions"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed">Premium Note Cards Delivered On Time</h2>
            <h3 className="small-hed">Focus on communication while we handle your note card printing needs </h3>
            <p>Printingy is dedicated to providing exceptional note card printing services that combine style and functionality. Whether for corporate branding, event invitations, or personal messages, our custom note card designing services ensure visually appealing and high-quality results.</p>
            <p>Our commitment to quality and efficiency means you can trust us to deliver bulk orders of note cards with precision and timeliness. With Printingy, your note cards will reflect your professionalism and leave a lasting impression.</p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default CustomNoteCards;
