import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const PresentationPrinting = () => {

  const servicesFaqData = [
    { 
      question: "What services does Printingy provide for presentations?", 
      answer: "Printingy offers comprehensive solutions for PowerPoint presentation design services and printing. Our team of presentation design experts collaborates with you to create impactful designs, while our printing services ensure sharp visuals and durable materials. Additional options include binding, lamination, and eco-friendly finishes to meet your requirements."
  },
  { 
      question: "Can Printingy handle bulk orders for presentation printing?", 
      answer: "Yes, Printingy specialises in bulk presentation printing, catering to businesses, educational institutions, and event organisers. Bulk printing ensures consistent quality across all copies, making it ideal for large-scale training sessions, corporate conferences, or marketing events. Our advanced technology guarantees professional results and timely delivery for all bulk orders."
  },
  { 
      question: "What industries benefit from custom presentation printing services?", 
      answer: "Custom presentation printing services are widely used in corporate offices, education, healthcare, and event management industries. Businesses rely on presentation design consultants for boardroom materials, while educators use our services for lectures or training sessions. Printingy provides tailored solutions for various industries, ensuring professional and impactful results."
  },
  { 
      question: "What materials and finishes are available for presentations?", 
      answer: "Printingy offers a variety of materials and finishes for presentation printing services, including premium paper, matte, glossy, and textured options. Our binding solutions include spiral, comb, and hardcover options to give your presentations a polished and durable appearance. These choices ensure your materials are professional, functional, and visually appealing."
  },
  { 
      question: "Does Printingy provide design assistance for presentations?", 
      answer: "Yes, our presentation design agency services include professional design assistance. Whether you need help refining your slides or creating a layout from scratch, our team works closely with you to deliver presentations that align with your goals. From optimising content to enhancing visuals, we ensure your presentations are functional and aesthetically pleasing."
  },
  { 
      question: "What is the typical turnaround time for bulk presentation printing?", 
      answer: "Turnaround times for presentation printing services depend on the size and complexity of the project. Standard bulk orders are typically completed within a few business days. For highly customised designs or large volumes, we provide clear timelines and prioritise meeting your deadlines without compromising quality."
  },
  { 
      question: "Are eco-friendly options available for presentation printing?", 
      answer: "Yes, Printingy offers eco-friendly options for PowerPoint presentation design services and printing. We use recyclable materials, non-toxic inks, and sustainable production methods to reduce environmental impact. These options are perfect for businesses and organisations aligning their practices with eco-conscious values."
  },
  { 
      question: "What factors should I consider when ordering presentation printing?", 
      answer: "Consider the audience, purpose, and content layout when ordering custom presentation printing. Selecting the right paper type, binding, and design elements ensures your presentations are functional and visually appealing. Printingy’s team guides the process to help you achieve the best results."
  },
  { 
      question: "How do I place an order for presentation printing services?", 
      answer: "Placing an order is simple and efficient. Visit our website to select your preferred PowerPoint presentation design services or bulk printing. Upload your files or share your requirements; our team will guide you through the process. We ensure a seamless experience from design approval to delivery."
  },
  { 
      question: "What makes bulk presentation printing cost-effective?", 
      answer: "Bulk presentation printing reduces per-unit costs, making it a cost-effective option for large-scale needs. Printingy delivers high-quality results at competitive prices by consolidating materials and streamlining production. Our bulk services ensure you get exceptional value while maintaining professional standards."
  }
];

    
    
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Postcard Printing Services",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
    <MetaManager 
  title="Professional Presentation Printing and Design Services"
  description="Expert PowerPoint presentation design services and printing. Bulk solutions by presentation design consultants and experts for businesses and events."
  keywords="Presentation design agency,Presentation design expert,PowerPoint presentation design services,Presentation design consultant"
/>


      <header className="services-header text-center py-5  serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Custom Presentation Printing Services</h1>
              <h2 className="text-muted">Professional design and printing of PowerPoint presentations</h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>Presentations are a cornerstone of corporate and educational communication, requiring a perfect balance of design, clarity, and functionality. Printingy offers professional PowerPoint presentation design services and high-quality printing solutions tailored to businesses, conferences, and training programs.</p>
          <p>Our team of presentation design experts collaborates with you to create impactful visuals that align with your brand identity and objectives. Whether you need detailed slides for internal use or polished layouts for client-facing materials, we ensure excellence in design and print. As a presentation design agency, we specialise in bulk presentation printing, offering consistent quality and quick turnarounds.</p>
          <p>Printingy provides end-to-end services for all your presentation needs, from slide creation to professional binding and finishing. Trust us to elevate your materials and deliver presentations that make a lasting impression.</p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3  rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">Professional PowerPoint Presentation Printing</h2>
                <h3 className="large-hed">Reliable, affordable, and expert presentation printing solutions</h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Expert presentation design consultants for impactful layouts.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Reliable PowerPoint presentation design services with high-quality prints.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Bulk printing options for large-scale training, conferences, or events.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Advanced technology for sharp visuals and vibrant colours.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Custom binding and finishing for a professional appearance.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Eco-friendly materials and sustainable printing practices.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Fast turnarounds with efficient project management systems.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by businesses, educational institutions, and event organisers.

                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Professional presentation printing services"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed">High-Quality Presentations Delivered On Time</h2>
            <h3 className="small-hed">Focus on your delivery while we handle your presentation printing needs </h3>
            <p>At Printingy, we combine design expertise and advanced technology to deliver exceptional presentation printing services. Whether you need support from a presentation design consultant or bulk printing for a corporate event, we ensure your presentations meet the highest professional standards.</p>
            <p>Our commitment to quality and efficiency guarantees timely delivery, even for complex projects. Partner with Printingy to elevate your presentations and captivate your audience with polished materials.</p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default PresentationPrinting;
