import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const CustomEnvelopes = () => {

  const servicesFaqData = [
    { question: "What customisation options are available for business envelopes?", answer: "At Printingy, you can customise your envelopes with a wide range of options. Choose from different sizes, paper qualities, and finishes to match your business needs. Our personalised envelopes for business services also allow you to add logos, company details, and unique designs to align with your branding. Whether for corporate letters or event invitations, we ensure every envelope is tailored to your specifications." },
    { question: "Does Printingy handle bulk envelope printing orders?", answer: "Yes, we specialise in bulk envelope design and printing to cater to businesses of all scales. Bulk orders are ideal for organisations needing large quantities for regular use or specific campaigns. Our advanced printing technology ensures consistent quality across all envelopes, regardless of volume. We also offer fast turnaround times to meet your deadlines efficiently." },
    { question: "What materials are used for envelope printing?", answer: "Printingy uses high-quality materials, including premium-grade paper, to ensure your envelopes are durable and professional. Options include matte, glossy, or textured finishes to match your style preferences. For businesses aiming to be eco-friendly, we also offer recyclable paper and sustainable inks. These materials guarantee that your custom business envelopes are visually appealing and environmentally responsible." },
    { question: "Does Printingy offer design assistance for envelopes? ", answer: "We provide design assistance as part of our envelope design and printing services. Our team can help create or refine your envelope design, ensuring it aligns with your branding. Whether you want a minimalist look or a bold statement design, we collaborate with you to bring your vision to life. This service ensures that your envelopes are both functional and visually impactful." },

    { question: "What is the typical turnaround time for envelope printing?", answer: "The turnaround time depends on the size and complexity of your order. Standard orders for personalised envelopes for business are usually completed within a few business days. Bulk or custom designs may take slightly longer, but we always communicate clear timelines. Our efficient processes ensure you receive your order on time, regardless of size." },
    { question: "Are eco-friendly envelope printing options available?", answer: "Yes, sustainability is a key focus at Printingy. We offer eco-friendly custom envelopes for business printed on recyclable paper using non-toxic inks. These options reduce environmental impact without compromising on quality. Businesses looking to align their printing needs with sustainable practices will find our solutions ideal for their goals." },
    { question: "What industries does Printingy serve for envelope printing?", answer: "Printingy serves various industries, including legal firms, educational institutions, real estate agencies, and corporate offices. Our envelope design and printing solutions cater to different needs, such as professional correspondence, marketing campaigns, and event invitations. No matter your industry, we deliver envelopes that effectively represent your brand." },
    { question: "Can I order envelopes in multiple sizes? ", answer: "Yes, Printingy offers customisation in envelope sizes to meet various business needs. Whether you need standard sizes for letters or unique dimensions for promotional materials, we accommodate all requirements. This flexibility ensures that your personalised envelopes for business are functional and practical for their intended purpose." },
    { question: "How do I place an order for envelope printing?", answer: "Placing an order with Printingy is straightforward. Visit our website to select your preferences for custom envelopes for business, upload your design, share your customisation details, and confirm your order. Our team manages the rest, ensuring a seamless design and delivery process." },
    { question: "What is the minimum order quantity for envelope printing?", answer: "We do not impose strict minimum order requirements. Whether you need a small batch for exclusive events or bulk personalised envelopes for business for daily correspondence, we can accommodate your needs. This flexibility allows us to serve businesses of all sizes effectively." }
  ];
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Custom Envelopes",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
       <MetaManager 
  title="Custom Envelopes for Businesses with Personalised Designs"
  description="High-quality custom envelopes for business. Personalised envelopes and envelopes design and printing services tailored for professional needs."
  keywords="Custom envelopes for business,Personalised envelopes for business,Envelope design and printing"
/>
      <header className="services-header text-center py-5  serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Custom Envelopes for Professional Businesses</h1>
              <h2 className="text-muted">
              Tailored envelopes design and printing for corporate and marketing needs
              </h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4  shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>
          Envelopes play a crucial role in professional communication, representing your brand while protecting important documents. Printingy specialises in creating custom envelopes for businesses to meet various needs, whether for corporate correspondence, invitations, or promotional materials. Our services include.
          </p>
          <ul className="topListItem">
            <li>Personalised envelopes for business.</li>
            <li>Allowing you to integrate logos.</li>
            <li>Custom text.</li>
            <li>Brand-specific designs.</li>
          </ul>
          <p>
          Our envelope design and printing solutions cater to businesses of all sizes, offering customisation options in dimensions, paper quality, and finishes. From elegant matte finishes to vibrant, glossy textures, we ensure your envelopes stand out while fulfilling their functional purpose. We handle both small and bulk orders with consistent attention to quality and timeliness.
          </p>
          <p>Whether you are looking for creative designs or simple yet professional layouts, Printingy delivers envelopes that communicate professionalism and enhance your brand identity. Using advanced printing technology and sustainable materials, we ensure every envelope is impactful, practical, and memorable.</p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3  rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">Premium Envelopes Printing Solutions </h2>
                <h3 className="large-hed">Affordable and reliable custom envelope printing services</h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Unique and professional custom envelopes for business branding.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    High-quality personalised envelopes for business with premium finishes.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Flexible options for envelope design and printing in various sizes.


                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Affordable pricing for both small and large orders.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Sustainable materials for eco-conscious printing solutions.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Consistent quality with vibrant colours and durable paper.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    On-time delivery with efficient project management systems.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by professionals, startups, and corporate organisations nationwide.
                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Personalised envelopes for professional correspondence"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed">Personalised Envelopes Delivered with Precision</h2>
            <h3 className="small-hed">Focus on communication while we manage your envelope printing needs</h3>
            <p>
            At Printingy, we prioritise quality and efficiency in every envelope design and printing project. Our custom envelopes for business are crafted using advanced printing technology and premium materials, ensuring durability and a professional appearance.

            </p>
            <p>
            Our streamlined processes guarantee timely delivery without compromising excellence, whether for corporate correspondence or promotional campaigns. With Printingy, you can rest assured that your envelopes will enhance your brand’s professionalism and meet all your communication needs.

            </p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default CustomEnvelopes;
