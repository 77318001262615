import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import FooterBottom from "./components/FooterBottom";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";  // Ensure these are default exports
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import CustomFlyersPrintingServices from "./components/Services/CustomFlyersPrintingServices";
import CertificatePrintingServices from "./components/Services/CertificatePrintingServices";
import BusinessCardPrinting from "./components/Services/BusinessCardPrinting";
import CustomEnvelopes from "./components/Services/CustomEnvelopes";
import CustomNotepads from "./components/Services/CustomNotepads";
import StampsandEmbossers from "./components/Services/StampsandEmbossers";
import DoorHangerPrinting from "./components/Services/DoorHangerPrinting";
import CustomNoteCards from "./components/Services/CustomNoteCards";
import CustomPocketFolder from "./components/Services/CustomPocketFolder";
import SchoolCopyPrinting from "./components/Services/SchoolCopyPrinting";
import DocumentPrintingAndBinding from "./components/Services/DocumentPrintingAndBinding";
import PostcardPrintingandDesigning from "./components/Services/PostcardPrintingandDesigning";
import PresentationPrinting from "./components/Services/PresentationPrinting";
import RackCardDesign from "./components/Services/RackCardDesign";
import ScrollToTop from "./ScrollToTop";
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/Header.css';
import './App.css'
const App = () => {
  return (
    <>
    <ScrollToTop />
      {/* Common Header */}
      <Header />
      
      {/* Main Content */}
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          {/* Corrected CustomFlyersPrintingServices route */}
          <Route path="/custom-flyers-printing-services" element={<CustomFlyersPrintingServices />} />
          <Route path="/certificate-printing-services" element={<CertificatePrintingServices />} />
          <Route path="/business-card-printing" element={<BusinessCardPrinting />} />
          <Route path="/custom-envelops" element={<CustomEnvelopes />} />
          <Route path="/custom-notepads" element={<CustomNotepads />} />
          <Route path="/stamps-and-embossers" element={<StampsandEmbossers />} />
          <Route path="/door-hanger-printing" element={<DoorHangerPrinting />} />
          <Route path="/custom-note-cards" element={<CustomNoteCards />} />
          <Route path="/custom-pocket-folder" element={<CustomPocketFolder />} />
          <Route path="/school-copy-printing" element={<SchoolCopyPrinting />} />
          <Route path="/document-printing-and-binding" element={<DocumentPrintingAndBinding />} />
          <Route path="/postcard-printing-and-designing" element={<PostcardPrintingandDesigning />} />
          <Route path="/presentation-printing" element={<PresentationPrinting />} />
          <Route path="/rack-card-design" element={<RackCardDesign />} />
       
        </Routes>
      </main>
      
      {/* Common Footer */}
      <Footer />
      <FooterBottom />
    </>
  );
};

export default App;
