import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import OtherServicesCarousel from "../OtherServicesCarousel";
import MetaManager from "../MetaManager";
import './Services.css';
const CustomFlyersPrintingServices = () => {

  const servicesFaqData = [
    { question: "What customisation options are available for flyers?", answer: "At Printingy, we provide a range of customisation options to meet your unique flyer design needs. You can select sizes, finishes, colours, and layouts to align with your campaign goals. Our custom flyer printing services also allow you to incorporate branding elements such as logos, images, and specific fonts. This ensures your flyers are professional and effective in capturing your target audience’s attention." },
    { question: "Can Printingy handle bulk online flyer printing orders?", answer: "Yes, Printingy specialises in handling bulk orders for online flyer printing. Whether you need hundreds or thousands of flyers, our team ensures consistent quality across all prints. We use advanced machinery and strict quality control processes to ensure every flyer meets professional standards. No matter the order size, we maintain a fast turnaround time to meet your marketing deadlines." },
    { question: "What are the design options available for business flyers?", answer: "Our business flyer design options offer complete flexibility to create impactful marketing materials. You can choose from pre-made templates or work with our designers for custom layouts. We also provide creative input on colours, graphics, and content placement to enhance the overall look of your flyers. These options ensure your flyers convey your message clearly while maintaining visual appeal." },
    { question: "How quickly can I get my flyers printed?", answer: "Printingy provides some of the fastest turnaround times in the industry for online flyer printing. Standard orders are typically completed within a few business days, while larger or highly customised projects may take slightly longer. We prioritise efficiency without compromising quality, ensuring your flyers are delivered on time for your campaigns or events." },

    { question: "What finishes are available for flyer printing? ", answer: "We offer a variety of finishes for flyer design and printing, including matte, glossy, and textured options. Each finish adds a unique touch to the flyers, enhancing their appeal and durability. Glossy finishes provide a vibrant, polished look, while matte finishes are subtle and professional. Textured finishes add an artistic element, making your flyers stand out." },
    { question: "Does Printingy use eco-friendly materials for flyer printing?", answer: "Yes, sustainability is a key focus at Printingy. Our custom flyer printing services utilise recyclable paper and non-toxic inks to minimise environmental impact. We are committed to offering eco-friendly solutions without compromising your printed materials' quality or durability." },
    { question: "How do I ensure my flyer design is effective?", answer: "An effective flyer design combines clear visuals, compelling content, and a strategic layout. At Printingy, our flyer design and printing team collaborates with clients to create designs that resonate with the target audience. By using professional graphics, concise messaging, and high-quality printing techniques, we help maximise the impact of your flyers." },
    { question: "What industries does Printingy serve for flyer printing?", answer: "Printingy caters to various industries, including retail, real estate, education, hospitality, and events. Our business flyers design solutions are tailored to meet the unique requirements of each sector. Whether you are promoting a product launch or organising an event, we create flyers that effectively convey your message." },
    { question: "Can I review my flyer design before printing?", answer: "Yes, Printingy provides a proofing process to ensure your satisfaction before printing begins. Once your flyer design is finalised, we share a digital proof for your review and approval. This step ensures your custom flyer printing project is executed exactly as planned." },
    { question: "What is the minimum order quantity for flyer printing? ", answer: "We have no strict minimum order requirements for flyer printing. Whether you need a small batch for a niche campaign or online flyer printing in bulk, we cater to all order sizes. Our services are designed to be flexible, allowing you to scale your printing needs according to your goals." }
   

  ];
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Custom Flyers Printing Services",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
       <MetaManager 
  title=" Flyers Design and Printing for Businesses"
  description="Get online flyer printing with custom designs. Perfect for business flyers, events, and promotions. Affordable and reliable flyer printing services."
  keywords="Flyers design and printing,Online flyer printing,Custom flyer printing,Business flyers design"
/>
      <header className="services-header text-center py-5 serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Custom Flyers Printing Services</h1>
              <h2 className="text-muted">
                Professional flyer design and printing for impactful marketing
              </h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>
            Flyers are one of the most versatile tools for marketing campaigns,
            helping businesses effectively communicate their message to a broad
            audience. Printingy specialises in premium flyer design and printing
            services that ensure your flyers stand out in crowded marketplaces.
            Our customisation options enable you to design flyers that perfectly
            reflect your brand’s identity, with vibrant visuals, crisp text, and
            professional layouts.
          </p>
          <p>
            With custom flyer printing, you can add unique elements such as
            logos, colour themes, and compelling content that align with your
            campaign goals. For added convenience, our online flyer printing
            services allow you to place and manage orders effortlessly from
            anywhere. Whether you need a small batch for a niche campaign or
            business flyers designed for a large-scale promotion, Printingy
            delivers high-quality results with quick turnarounds.
          </p>
          <p>We use advanced technology and sustainable materials to ensure every flyer meets premium standards. From businesses and event organisers to educational institutions and retailers, we cater to diverse industries, providing reliable and impactful flyer printing services.</p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3  rounded mb-4">
            <Row>
              <Col lg={6}>
                <h3>Custom Flyer Printing Experts</h3>
                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Engaging and creative flyer design and printing.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Reliable online flyer printing with doorstep delivery.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Flexible options for custom flyer printing in any size or format.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Affordable solutions for both small and bulk orders.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Eco-friendly printing materials and sustainable practices.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Vibrant colours and professional finishes for an eye-catching look.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    On-time delivery with efficient order management systems.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by businesses, startups, and event organisers nationwide.
                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Custom Flyer Printing Experts"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h3 className="text-primary">Professional Flyer Printing with Precision</h3>
            <p>
              Printingy is committed to delivering top-notch flyer design and
              printing services that align with your marketing goals. Our online
              flyer printing solutions ensure convenience without compromising
              quality. Whether it’s a small batch or a large custom flyer printing
              order, we always guarantee exceptional results.
            </p>
            <p>
              Our attention to detail, advanced technology, and reliable processes
              enable us to meet tight deadlines while maintaining outstanding print
              quality. With Printingy, you can focus on your business while we
              create impactful flyers that leave a lasting impression.
            </p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>


      {/* FAQ Section */}
      <Faq faqData={servicesFaqData} />
    </div>
  );
};

export default CustomFlyersPrintingServices;
