import React from 'react';
import Banner from '../components/About/Banner/Banner';
import AboutUs from '../components/About/AboutUs';
import Role from '../components/About/Role/Role';
import CallToAction from "../components/Home/CallToAction/CallToAction"
const About = () => {
  return (
   <div>
    <Banner 
                imageUrl="/banners4.jpg" 
                altText="Home Page Banner" 
            />
            <Role />
     <AboutUs />
     <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />

   </div>
     
  );
};

export default About;
