import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const DoorHangerPrinting = () => {

    const servicesFaqData = [
        { question: "What customisation options are available for door hangers?", answer: "Printingy offers a range of customisation options for door hanger printing to suit your business needs. You can choose sizes, shapes, and paper types to create door hangers that align with your branding. Our door hanger design and printing services allow you to include logos, images, and tailored messages for maximum impact. These customisation options ensure your door hangers are unique and effective in delivering your message." },
        { question: "Can Printingy handle bulk orders for door hangers?", answer: "Yes, Printingy specialises in bulk door hangers for businesses, ensuring consistent quality across all prints. Bulk orders are ideal for businesses running large-scale promotional campaigns or distributing materials to multiple locations. Our advanced printing systems ensure sharp designs, vibrant colours, and timely delivery, regardless of order size." },
        { question: "What industries can benefit from door hangers?", answer: "Door hangers are versatile tools used across various industries, including hospitality, real estate, retail, and food services. Hotels can use them for in-room communication, while real estate agents and restaurants can distribute promotional messages. Printingy provides door hangers for businesses catering to each industry's unique needs, ensuring professional and impactful results." },
        { question: "What materials are used for door hanger printing?", answer: "We use premium-quality door hanger printing materials, including durable card stock and eco-friendly paper options. These materials ensure the door hangers are sturdy enough for repeated use and visually appealing for effective marketing. With finishes such as matte, glossy, and UV coating, we enhance the durability and aesthetics of your door hangers." },
        { question: "Does Printingy assist with door hanger design?", answer: "Yes, our door hanger design and printing services include design assistance to help bring your vision to life. Whether you have a ready concept or need creative input, our design team collaborates to create engaging and professional door hangers. We focus on clear messaging, attractive visuals, and functional layouts to maximise the effectiveness of your campaign." },
        { question: "What is the typical turnaround time for bulk door hanger orders?", answer: "The turnaround time for door hangers for business depends on the size and complexity of the order. Standard bulk orders are usually completed within a few business days. For highly customised designs or exceptionally large orders, we provide clear timelines and prioritise meeting your deadlines without compromising quality." },
        { question: "Are eco-friendly door hanger printing options available?", answer: "Yes, Printingy is committed to sustainability and offers eco-friendly door-hanger printing options. We use recyclable paper, non-toxic inks, and sustainable production practices to reduce environmental impact. These options allow you to maintain marketing effectiveness while aligning with eco-conscious values." },
        { question: "What makes door hangers an effective marketing tool?", answer: "Door hangers are unique and attention-grabbing, highly effective for targeted marketing. Traditional flyers are more likely to be noticed as they are placed directly on doors. Printingy’s door hanger design and printing services ensure your materials stand out with vibrant visuals and compelling messages, driving better engagement with your audience." },
        { question: "How do I place an order for door hanger printing?", answer: "Placing an order is simple. Visit our website and choose your preferences for door hanger design and printing. Upload your design files or share your requirements, and our team will guide you through the process. We ensure a seamless experience from design approval to bulk delivery." },
        { question: "What factors should I consider when ordering bulk door hangers?", answer: "Consider the purpose, target audience, and distribution method when ordering bulk door hangers for business. Selecting the right size, material, and design features is essential to create an effective marketing tool. Printingy’s team can guide you to ensure your door hangers meet your objectives and deliver maximum impact." }
    ];
    
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Custom Envelopes",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
       <MetaManager 
  title=" Door Hanger Printing Services – custom design"
  description="Professional door hanger printing for businesses. Get bulk custom door hanger design and printing for promotional and branding needs."
  keywords="Custom envelopes for business,Personalised envelopes for business,Envelope design and printing"
/>
      <header className="services-header text-center py-5  serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Professional Door Hanger Printing Services</h1>
              <h2 className="text-muted">
              Custom door hanger design and printing tailored for businesses
              </h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>
          Door hangers are an effective and versatile marketing tool, ideal for businesses looking to capture attention uniquely. Printingy specialises in door hanger printing services designed to elevate your brand visibility. With fully customisable options, our door hanger design and printing solutions allow you to incorporate logos, brand colours, and compelling messages.
          </p>
         
          <p>
          Perfect for hotels, real estate agencies, restaurants, and events, our business door hangers are crafted using premium materials to ensure durability and professionalism. Whether you need a small batch for targeted campaigns or bulk orders for wide distribution, Printingy ensures consistent quality across all prints.
          </p>
          <p>Our advanced printing technology and attention to detail ensure that your door hangers make a strong impression. From promotional messages to branded communication, trust Printingy to deliver door hangers that stand out and enhance your marketing efforts.</p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3  rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">High-Quality Custom Door Hangers</h2>
                <h3 className="large-hed">Affordable and reliable door hanger printing services</h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Creative and professional door hanger design and printing solutions.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Durable door hangers for business in various sizes and finishes.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Bulk printing options for large-scale promotional campaigns.


                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Eco-friendly materials for sustainable marketing.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Sharp prints, vibrant colours, and premium paper quality.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Fast turnarounds with efficient order management.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by hotels, real estate agencies, and businesses nationwide.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Competitive pricing for high-volume orders without compromising quality.
                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Custom door hanger printing services"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed">Professional Door Hangers Delivered On Time </h2>
            <h3 className="small-hed">Focus on marketing while we manage your printing needs </h3>
            <p>
            Printingy delivers premium door hanger printing services tailored to your brand’s requirements. Whether a small local campaign or a nationwide promotional effort, our door hanger design and printing solutions ensure exceptional quality and precision.
            </p>
            <p>
            Our streamlined processes and advanced technology enable us to handle bulk orders efficiently, ensuring timely delivery without sacrificing quality. Trust Printingy to help you create impactful door hangers that resonate with your audience and strengthen your marketing strategy.
            </p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default DoorHangerPrinting;
