import React from "react";
import './Banner.css'
export default function Banners({ imageUrl, altText }) {
    return (
        <div className="banners">
            <img src={imageUrl} alt={altText} className="banners-image" />
            <h1>About Us</h1>
        </div>
    );
}
