import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const RackCardDesign = () => {

    const servicesFaqData = [
        { 
            question: "What customisation options are available for rack cards?", 
            answer: "Printingy offers a range of customisation options for rack card printing, including sizes, paper types, and finishes. You can choose matte, glossy, or textured finishes to suit your branding and aesthetic preferences. Our custom rack card design and printing services also allow you to include logos, images, and tailored content to ensure your cards effectively communicate your message."
        },
        { 
            question: "Does Printingy handle bulk rack card printing orders?", 
            answer: "Yes, Printingy specialises in bulk rack card printing, making it ideal for businesses with large-scale marketing needs. Bulk orders ensure consistent quality across all cards while offering cost-effective solutions. Our advanced technology and efficient processes guarantee professional results and timely delivery for all bulk printing projects."
        },
        { 
            question: "What industries benefit from custom rack card printing?", 
            answer: "Custom rack card printing is widely used across hospitality, tourism, retail, and event management industries. Hotels use rack card designs for local attractions, while retail stores rely on them for promotions and product highlights. Printingy tailors its custom rack card design and printing services to meet the unique requirements of each industry, ensuring impactful results."
        },
        { 
            question: "What materials and finishes are available for rack cards?", 
            answer: "We use high-quality custom rack card printing materials, including durable card stock and eco-friendly options. Finishes such as matte, glossy, or UV coating enhance your rack cards' visual appeal and durability. These materials ensure your printed cards maintain a professional appearance, making them suitable for various marketing purposes."
        },
        { 
            question: "Does Printingy provide design assistance for rack cards?", 
            answer: "Yes, our rack card design services include professional design assistance. Whether you have a concept in mind or need help creating one, our team collaborates with you to produce engaging and functional layouts. From choosing fonts to arranging graphics, we ensure your rack cards align with your branding and marketing goals."
        },
        { 
            question: "What is the typical turnaround time for rack card printing?", 
            answer: "Turnaround times for printing rack cards depend on the order's size and complexity. Standard bulk orders are typically completed within a few business days. For highly customised designs or larger volumes, we provide clear timelines and prioritise meeting your deadlines without compromising quality."
        },
        { 
            question: "Are eco-friendly options available for rack card printing?", 
            answer: "Printingy is committed to sustainability and offers eco-friendly options for custom rack card design and printing. We use recyclable paper, non-toxic inks, and sustainable production methods to minimise environmental impact. These options are ideal for businesses aligning their marketing efforts with eco-conscious values."
        },
        { 
            question: "What factors should I consider when ordering rack cards?", 
            answer: "Consider the purpose, target audience, and distribution method when ordering custom rack card design and printing. Selecting the right paper type, size, and design elements ensures your cards are effective and visually appealing. Printingy’s team guides the process to help you create rack cards that achieve your marketing objectives."
        },
        { 
            question: "How do I place an order for rack card printing?", 
            answer: "Placing an order is simple and efficient. Visit our website and select your custom rack card design and printing preferences. Upload your design files or share your requirements, and our team will guide you through the process. We ensure a seamless experience from design approval to delivery."
        },
        { 
            question: "What makes bulk rack card printing cost-effective?", 
            answer: "Bulk rack card printing reduces per-unit costs, making it an economical choice for large-scale marketing campaigns. Printingy delivers high-quality results at competitive prices by consolidating materials and streamlining production. Our bulk rack card printing services ensure exceptional value while maintaining professional standards."
        }
    ];
    
    
    
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Rack Cards Printing",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
    <MetaManager 
  title="Custom Rack Card Design and Printing Services"
  description="Professional rack card printing for businesses. Bulk printing with custom rack card design and solutions tailored to your brand."
  keywords="Copy printing service,Copy printing in bulk,School copy-printing services"
/>


      <header className="services-header text-center py-5  serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Professional Rack Cards Printing Services</h1>
              <h2 className="text-muted">Custom rack card design and printing tailored to your business needs</h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>Rack cards are a compact and effective marketing tool for promoting services, products, or events. Printingy offers premium rack card printing services that combine creativity with functionality. Our custom rack card design and printing services allow you to create visually striking cards that convey your message clearly and effectively.</p>
          <p>Whether you need custom rack card printing for hotels, retail stores, or events, our solutions are crafted to ensure lasting impressions. From professional layouts to vibrant colours, we guarantee high-quality results that align with your branding. Our expertise in rack card design ensures every detail is meticulously executed to maximise engagement.</p>
          <p>With options for bulk printing, eco-friendly materials, and durable finishes, Printingy provides a one-stop solution for all your rack card needs. Trust us to deliver professional and impactful rack cards that help your business stand out.</p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3 rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">Reliable and Customised Rack Card Printing</h2>
                <h3 className="large-hed">Affordable and high-quality rack card design and printing services </h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Creative rack card design services tailored to your branding.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    High-quality rack card printing with vibrant colours and sharp details.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Bulk printing solutions for cost-effective marketing.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Eco-friendly materials for sustainable printing practices.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Durable finishes, including matte, glossy, and textured options.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Quick turnarounds and efficient order management systems.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by businesses, event organisers, and retail establishments nationwide.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Competitive pricing for bulk orders without compromising quality.
                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Professional rack card printing services"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed">Premium Rack Cards Delivered On Time</h2>
            <h3 className="small-hed">Focus on your marketing strategy while we handle your rack card printing needs</h3>
            <p>At Printingy, we specialise in exceptional custom rack card printing services combining style and practicality. Our rack card design and printing solutions ensure professional and impactful results, whether for promotional campaigns, events, or in-store displays.</p>
            <p>Our streamlined processes and advanced printing technology enable us to handle bulk orders efficiently while maintaining the highest quality standards. With Printingy, you can trust us to deliver rack cards that resonate with your audience and enhance your brand visibility.</p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default RackCardDesign;
