import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const PostcardPrintingandDesigning = () => {

    const servicesFaqData = [
        { question: "What customisation options are available for postcards?", answer: "Printingy offers extensive customisation options for custom postcard printing, including sizes, paper types, and finishes. You can choose matte, glossy, or textured finishes to match your branding needs. Our custom postcard designing and printing services also allow you to include logos, images, and personalised messages. These options ensure your postcards are visually appealing and effectively convey your message." },
        { question: "Does Printingy specialise in bulk postcard printing?", answer: "Yes, Printingy focuses on postcard printing in bulk for businesses and organisations. Bulk orders are ideal for marketing campaigns, event promotions, or client outreach. Our advanced printing technology and efficient processes ensure consistent quality and timely delivery for all bulk orders, making them cost-effective for large-scale needs." },
        { question: "What industries benefit from postcard printing services?", answer: "Postcard printing services are widely used in real estate, hospitality, retail, and event management industries. Businesses use our design and print postcard services for promotional campaigns, while event organisers rely on them for invitations or announcements. Printingy provides tailored solutions to meet the unique needs of each industry, ensuring professional and impactful results." },
        { question: "What materials are used for postcard printing?", answer: "We use premium materials for custom postcard printing, including high-quality card stock and eco-friendly options. Finishes such as matte, glossy, or textured enhance your postcards' visual appeal and durability. These materials ensure that your printed postcards maintain their professional look and feel, making them suitable for various purposes." },
        { question: "Does Printingy provide design assistance for postcards?", answer: "Yes, our custom postcard designing and printing services include professional design assistance. Whether you have a ready concept or need creative input, our design team collaborates to create layouts that align with your branding. From selecting fonts to arranging visuals, we ensure your postcards are functional and visually striking." },
        { question: "What is the typical turnaround time for postcard printing?", answer: "Turnaround times for postcard printing depend on the size and complexity of your order. Standard bulk orders are typically completed within a few business days. For highly customised designs or larger volumes, we provide clear timelines and prioritise meeting your deadlines without compromising quality." },
        { question: "Are eco-friendly options available for postcard printing?", answer: "Yes, Printingy is committed to sustainability and offers eco-friendly options for custom postcard printing. We use recyclable paper and non-toxic inks to reduce environmental impact while maintaining premium quality. These options are ideal for businesses aligning their marketing efforts with eco-conscious values." },
        { question: "What factors should I consider when ordering custom postcards?", answer: "Consider the purpose, audience, and distribution method when ordering custom postcards, designing and printing. Choosing the right paper type, size, and design elements ensures your postcards are effective and visually appealing. Printingy’s team guides the process to help you create postcards that deliver the desired results." },
        { question: "How do I place an order for postcard printing?", answer: "Placing an order is simple and efficient. Visit our website and choose your preferences for design & print postcards. Upload your design files or share your requirements, and our team will guide you through the process. We ensure a seamless experience from design approval to delivery." },
        { question: "What makes bulk postcard printing cost-effective?", answer: "Bulk postcard printing reduces per-unit costs, making it a cost-effective option for large-scale marketing needs. Printingy delivers high-quality results at competitive prices by consolidating materials and streamlining production. Our bulk postcard printing services ensure exceptional value without compromising on quality." }
    ];
    
    
    
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Postcard Printing Services",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
    <MetaManager 
  title=" Custom Postcard Printing and Designing Services"
  description="Get professional postcard printing services. We offer bulk printing of custom postcards with designing and printing options for businesses and events."
  keywords="Copy printing service,Copy printing in bulk,School copy-printing services"
/>


      <header className="services-header text-center py-5  serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Professional Postcard Printing Services </h1>
              <h2 className="text-muted">Custom postcard designing and printing for impactful communication</h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>Postcards are a timeless and versatile marketing, communication, and branding tool. Printingy offers high-quality postcard printing services tailored to meet your business needs. Our expertise includes custom postcard printing and design & print postcard solutions that help you deliver impactful messages.</p>
          <p>Our postcards are crafted using premium materials and advanced printing technology for promotional campaigns, corporate invitations, or personalised greetings. We also provide complete custom postcard designing and printing services to create unique and professional layouts that reflect your brand identity.</p>
          <p>With flexible sizes, finishes, and customisation options, Printingy ensures that every postcard meets your specifications. From small batches to bulk orders, we deliver consistent quality and reliable service to help your business connect with its audience effectively.</p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3  rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">High-Quality Custom Postcard Printing</h2>
                <h3 className="large-hed">Affordable and reliable postcard printing services for businesses </h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Customised design & print postcard services for unique branding.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    High-quality custom postcard printing with vibrant colours and sharp details.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Bulk printing options for large-scale marketing campaigns or events.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Eco-friendly materials and sustainable printing practices.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Durable finishes, including matte, glossy, and textured options.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Fast turnarounds and efficient order management systems.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by businesses, event organisers, and marketers nationwide.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Competitive pricing for high-volume orders without compromising quality.

                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Professional document printing services"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed">Premium Postcards Delivered On Time</h2>
            <h3 className="small-hed">Focus on your message while we handle your postcard printing needs</h3>
            <p>At Printingy, we specialise in providing exceptional postcard printing services that combine style and functionality. Whether you need custom postcard designing and printing for a special event or bulk printing for a marketing campaign, we ensure every order meets your requirements.</p>
            <p>Our streamlined processes and advanced printing technology enable us to deliver on time without sacrificing quality. With Printingy, you can rely on us for professional postcards that leave a lasting impression.</p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default PostcardPrintingandDesigning;
