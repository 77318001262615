import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MetaManager from "../MetaManager";
import OtherServicesCarousel from "../OtherServicesCarousel";
import CallToAction from "../Home/CallToAction/CallToAction";
import Faq from "./Faq";
import './Services.css';
const DocumentPrintingAndBinding = () => {

    const servicesFaqData = [
        { question: "What customisation options are available for document printing?", answer: "Printingy offers extensive customisation options for document printing services. To meet your needs, you can select paper types, sizes, finishes, and binding options. Our custom document creation and printing services also allow for the inclusion of logos, graphics, and tailored layouts. These customisations ensure your documents are professional, functional, and visually appealing." },
        { question: "Does Printingy specialise in bulk document printing?", answer: "Yes, Printingy focuses on bulk document printing services, catering to businesses and organisations with large-scale needs. Bulk printing ensures consistent quality and cost-effective production, making it ideal for contracts, certificates, and other official documents. Our advanced printing technology and efficient processes guarantee reliable results for all bulk orders." },
        { question: "What industries benefit from custom document printing services?", answer: "Custom document printing services are widely used in corporate offices, education, healthcare, and event management industries. Businesses rely on our document binding and printing solutions for reports, training manuals, and promotional materials. Educational institutions often require certificate prints and course materials, which we customise to meet their unique requirements." },
        { question: "What materials are used for document printing?", answer: "We use high-quality materials for custom document creation and printing, including durable paper and eco-friendly inks. Finishing options such as matte, glossy, or textured paper ensure a professional appearance. We offer sturdy and long-lasting options, including spiral, comb, and hardcover binding, to meet your preferences." },
        { question: "Does Printingy assist with document layout and design?", answer: "Yes, our document printing services include layout and design assistance. Whether you need help organising content, formatting graphics, or creating a cohesive design, our team collaborates with you to deliver polished and professional documents. This service ensures your printed materials effectively communicate your message while maintaining visual appeal." },
        { question: "What is the typical turnaround time for bulk document printing?", answer: "Turnaround times for creating and printing custom documents depend on the order's size and complexity. Standard bulk orders are typically completed within a few business days. For highly customised requests or larger volumes, we provide clear timelines and prioritise meeting your deadlines without compromising quality." },
        { question: "Are eco-friendly options available for document printing?", answer: "Yes, Printingy is committed to sustainability and offers eco-friendly options for document printing services. We use recyclable paper, non-toxic inks, and sustainable production methods to minimise environmental impact. These options are ideal for businesses and organisations seeking sustainable printing solutions." },
        { question: "What factors should I consider when ordering custom documents?", answer: "Consider the purpose, audience, and distribution method when ordering custom documents, creating and printing. Selecting the right paper type, binding option, and design layout ensures your documents are functional and visually appealing. Printingy’s team guides the process to help you achieve the desired results." },
        { question: "How do I place an order for custom document printing?", answer: "Placing an order is simple and efficient. Visit our website to select your preferences for certificate prints, binding styles, and custom layouts. Upload your files or share your requirements; our team will guide you through the process. We ensure a seamless experience from design approval to delivery." },
        { question: "What makes bulk document printing cost-effective?", answer: "Bulk document printing reduces per-unit costs, making it a cost-effective option for large-scale needs. Printingy delivers high-quality results at competitive prices by streamlining production and consolidating materials. Our bulk document printing services ensure exceptional value while maintaining professional standards." }
    ];
    
    
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Document Printing",
    message: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add functionality to send the form data to your backend or an email service
    alert("Your message has been sent!");
  };

  return (
    <div>
    <MetaManager 
  title="School Copy Printing Services in Bulk"
  description="Reliable copy printing service for businesses and schools. Bulk printing solutions tailored for school copies and corporate needs. High quality and timely delivery."
  keywords="Copy printing service,Copy printing in bulk,School copy-printing services"
/>


      <header className="services-header text-center py-5  serviceheader">
        <Container>
          <Row className="align-item-cen">
            <Col lg={6}>
              <h1>Custom Document Printing Services</h1>
              <h2 className="text-muted">
              Professional document printing and binding for businesses and organisations
              </h2>
            </Col>

            {/* Contact Form on the Right Side */}
            <Col lg={6}>
              <div className="contact-form p-4 bg-white shadow-sm rounded" style={{ maxWidth: "400px", margin: "auto" }}>
                <h3 className="text-center mb-4">Get in Touch</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                   
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email" className="mb-3">
                
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="service" className="mb-3">
                  
                    <Form.Control
                      type="text"
                      name="service"
                      value={formData.service}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group controlId="message" className="mb-3">
                 
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter your message"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="services-overview ">
        <Container>
          <p>Custom documents, from corporate reports to certificates and training materials, are an essential part of professional operations. Printingy specialises in document printing services designed to meet diverse business needs. Our offerings include certificate prints, tailored documents, and comprehensive document binding solutions for a polished finish.</p>
          <p>Whether you need professional custom documents creating and printing for official use, educational purposes, or branding materials, we deliver with precision and reliability. Our services are ideal for businesses requiring high-quality, consistent bulk printing solutions for contracts, manuals, certificates, or promotional materials.</p>
          <p>Using advanced printing technology and premium materials, Printingy ensures sharp text, vibrant images, and durable binding options. Let us handle your document printing needs so you can focus on achieving your goals.</p>
        </Container>
      </section>

      <section className="why-choose py-5">
        <Container>
          <h2 className="text-center mb-4">Why Choose Printingy</h2>

          {/* Custom Flyer Printing Experts */}
          <div className="py-4 px-3 rounded mb-4">
            <Row>
              <Col lg={6}>
              <h2 className="small-hed">Reliable Bulk Document Printing Services</h2>
                <h3 className="large-hed">High-quality, efficient, and cost-effective custom document printing</h3>

                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Professional document printing services with customisation options.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Specialised certificate prints for academic or corporate recognition.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Durable documents are binding for a polished and professional appearance.

                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Bulk printing solutions tailored for businesses, schools, and organisations.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Eco-friendly materials for sustainable printing practices.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Vibrant colours, sharp text, and premium finishes.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Competitive pricing with fast turnarounds and efficient order management.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                    Trusted by businesses, educational institutions, and event organisers.
                  </li>
                </ul>
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Col>

              {/* Contact Form on the Right Side */}
              <Col lg={6}>
                <img
                  src="/assets/img/cus-fly-1.jpg" // Path relative to the public folder
                  alt="Professional document printing services"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </div>
          <CallToAction 
        title="Join Our Newsletter"
        description="Stay updated with the latest news and offers!"
      />
          {/* Professional Flyer Printing with Precision */}
          <div className="shadow-sm p-4 rounded">
            <h2 className="large-hed">Premium Custom Documents Delivered On Time</h2>
            <h3 className="small-hed">Focus on your objectives while we manage your document printing needs</h3>
            <p>Printingy is dedicated to providing high-quality document printing services that combine professionalism and functionality. Whether for certificate prints, training manuals, or company reports, we ensure your custom documents meet your requirements.</p>
            <p>Our streamlined processes guarantee timely delivery for bulk orders, ensuring your documents are ready when needed. With Printingy, you can trust us to deliver exceptional results with every print.</p>
          </div>
        </Container>
      </section>



      <div>
  {/* Existing Content */}
  <OtherServicesCarousel />
  {/* Other Content */}
</div>

<Faq faqData={servicesFaqData} />
   
    </div>
  );
};

export default DocumentPrintingAndBinding;
