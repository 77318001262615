import React, { useState } from "react";
import "./Solutions.css";

export default function Solutions() {
    const tabs = [
        {
            id: 1,
            label: "Prints of Business & Career Essentials",
            images: [
                { src: "https://www.office.fedex.com/media/wysiwyg/copies_custom_documents_300x200.jpg", heading: "DOCUMENT PRINTING"  },
                { src: "https://www.office.fedex.com/media/wysiwyg/copies_custom_documents_300x200.jpg", heading: "PRINTING BUSINESS ESSENTIALS"},
                { src: "https://www.office.fedex.com/media/wysiwyg/copies_custom_documents_300x200.jpg", heading: "SPECIALTY PRINTS"},
            
                
            ],
        },
        {
            id: 2,
            label: "Marketing & Promotional Materials",
            images: [
                { src: "https://www.office.fedex.com/media/catalog/product/cache/a174834728267739057558ee97dc8a23/h/o/holiday_postcards_300x200.jpg", heading: "Printing of MARKETING MATERIALS"},
                { src: "https://www.office.fedex.com/media/catalog/product/cache/a174834728267739057558ee97dc8a23/h/o/holiday_postcards_300x200.jpg", heading: "Printing of Cards & Invitations"},
            ],
        },
        {
            id: 3,
            label: "Banners, Signs & Posters",
            images: [
                { src: "https://www.office.fedex.com/media/wysiwyg/BusinessCards_300x200.jpg", heading: "Printing of BANNERS"},
                { src: "https://www.office.fedex.com/media/wysiwyg/BusinessCards_300x200.jpg", heading: "Printing of SIGNS"},
                { src: "https://www.office.fedex.com/media/wysiwyg/BusinessCards_300x200.jpg", heading: "Printing of POSTERS"},
           
            ],
        },
        {
            id: 4,
            label: "Printing & Design Ideas",
            images: [
                { src: "https://www.office.fedex.com/media/catalog/product/cache/a174834728267739057558ee97dc8a23/w/i/winter_wonderland_flyer1_300x200.jpg", heading: "Printing Solution for Industries"},
            
            ],
        },
      
    ];

    const [selectedTab, setSelectedTab] = useState(tabs[0].id);

    const handleTabClick = (id) => {
        setSelectedTab(id);
    };

    const selectedTabContent = tabs.find((tab) => tab.id === selectedTab);

    return (
        <div className="tabs-container">
            <div className="sol-heading container">
                <h1 className="text-center">Comprehensive Printing Solutions</h1>
                <p>
                    Unlock the power of exceptional printing with our comprehensive range of services.
                    Whether you need striking business cards, eye-catching posters, or professional brochures,
                    we deliver quality that speaks volumes. Unlock the power of exceptional printing with our comprehensive range of services.
                    Whether you need striking business cards, eye-catching posters, or professional brochures,
                    we deliver quality that speaks volumes. Unlock the power of exceptional printing with our comprehensive range of services.
                    Whether you need striking business cards, eye-catching posters, or professional brochures,
                    we deliver quality that speaks volumes.
                </p>
            </div>

            {/* Tab Navigation */}
            <div className="tabs">
                {tabs.map((tab) => (
                    <button
                        key={tab.id}
                        className={`tab ${selectedTab === tab.id ? "active" : ""}`}
                        onClick={() => handleTabClick(tab.id)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            {/* Tab Content */}
            <div className="tab-content">
                <div className="images">
                    {selectedTabContent.images.map((image, index) => (
                        <div key={index} className="image-item">
                            <img src={image.src} alt={`Tab ${selectedTab} Image ${index + 1}`} className="tab-image" />
                            <h4 className="image-title">{image.heading}</h4>
                            {/* <p className="image-description">{image.description}</p> */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
