import React from "react";
import './Role.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
export default function Role(){

    return(
        <div className="container mr-t80">
           
            <div className="row">
                <div className="col-md-3">
                   <div className="innerAbout">
                   <h5 className="text-center">Tell me more
                    Review Your Order</h5>
              <div className="text-center">
              <div className="iconBg-1">
                <FontAwesomeIcon
                icon={faPrint}
                className="icon"
            />
                  </div>
              </div>
                  <p className="text-justify">For those who prefer a more personal touch, our physical sessions are conducted in a serene and welcoming environment designed to foster a therapeutic atmosphere where you can engage directly with our therapists and healers. </p>
                   </div>
                </div>
                <div className="col-md-3">
              <div className="innerAbout1">
              <h5 className="text-center">Tell me more
                Review Your Order</h5>
                 <div className="text-center">
                 <div className="iconBg-2">
                  <FontAwesomeIcon
                icon={faPrint}
                className="icon"
            />
                  </div>
                 </div>
                  <p className="text-justify">For those who prefer a more personal touch, our physical sessions are conducted in a serene and welcoming environment designed to foster a therapeutic atmosphere where you can engage directly with our therapists and healers. </p>
                </div>
              </div>
                <div className="col-md-3">
               <div className="innerAbout">
               <h5 className="text-center">Tell me more
                Review Your Order</h5>
              <div className="text-center">
              <div className="iconBg-3 ">
                <FontAwesomeIcon
                icon={faPrint}
                className="icon"
            />
                  </div>
              </div>
                  <p className="text-justify">For those who prefer a more personal touch, our physical sessions are conducted in a serene and welcoming environment designed to foster a therapeutic atmosphere where you can engage directly with our therapists and healers.</p>
               </div>
          


                </div>
                <div className="col-md-3">
                <div className="innerAbout1">
                <h5 className="text-center">Tell me more
                Review Your Order</h5>  
              <div className="text-center">
              <div className="iconBg-4">
                <FontAwesomeIcon
                icon={faPrint}
                className="icon"
            />
                  </div>
              </div>
                  <p className="text-justify">For those who prefer a more personal touch, our physical sessions are conducted in a serene and welcoming environment designed to foster a therapeutic atmosphere where you can engage directly with our therapists and healers.</p>
                </div>
                </div>
            </div>
        </div>
    )
}