import React from "react";
import './WhoWeServe.css';

export default function WhoWeServe() {
    const industries = [
        { id: 1, industries: "Retail", Description: "We specialize in helping retail businesses bring their branding to life with vibrant and high-quality print materials. From eye-catching point-of-sale displays to durable shopping bags and promotional materials, we ensure your brand stands out and creates a lasting impression on customers." },
        { id: 2, industries: "Education", Description: "Empowering educational institutions with professional-grade printed materials, including brochures, prospectuses, certificates, and customized stationery. Whether for schools, colleges, or training centers, our prints support effective communication and branding." },
        { id: 3, industries: "Corporate", Description: "Supporting the corporate world with premium printing solutions for business cards, letterheads, reports, and event materials. Our commitment to precision and professionalism helps businesses leave a lasting mark on their stakeholders." },
        { id: 4, industries: "Healthcare", Description: "Providing customized printing for healthcare providers, including patient forms, prescription pads, appointment cards, and informational brochures. Our solutions are tailored to meet the specific needs of clinics, hospitals, and healthcare organizations." },
        { id: 5, industries: "Hospitality", Description: "We deliver exquisite printing solutions for the hospitality sector, including menu cards, signage, event materials, and guest brochures. Our products enhance guest experiences and promote your brand in a sophisticated manner." },
        { id: 6, industries: "Events & Entertainment", Description: "Bringing events to life with bespoke printing for invitations, banners, posters, and promotional materials. From concerts to corporate events, we help you make every event memorable with our creative designs and flawless prints." },
    ];

    return (
        <div className="outer-who">
            <div className="container">
                <h2 className="text-center who-heading">Who We Serve</h2>
                <div className="row">
                    {industries.map((industry) => (
                        <div key={industry.id} className="col-md-4 ">
                         <div className="al-center">
                         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="30" height="30">
  
  <defs>
    <linearGradient id="tickGradient" gradientTransform="rotate(45)">
      <stop offset="0%" stop-color="#63cec98a" />
      <stop offset="100%" stop-color="#eeb0f699" />
    </linearGradient>
  </defs>

  
  <circle cx="50" cy="50" r="50" fill="url(#tickGradient)" />

 
  <path
    d="M30 50 L45 65 L70 35"
    stroke="white"
    stroke-width="8"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

  <h5  className="industries-heading">{industry.industries}</h5>
                         </div>
                            <p  className="industries-para">{industry.Description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
